import React, { useState, useEffect } from 'react';
import styles from './Chargeback.module.scss';
import { Divider, Tabs } from 'antd';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/OtherBalanceCard';
import BlockIcon from '../../assets/images/Icons/blockfunds.svg';
import BalanceIcon from '../../assets/images/Icons/newBalance.svg';
import BankIcon from '../../assets/images/Icons/newBank.svg';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/Icons/u_copy.svg';
import Select from 'react-select';
import OutlineTextInput from '../../components/input/OutlineTextInput';
import PaginationTable from '../../components/table/pagination-table';
import {
	BalanceHistoryData,
	ColumnBalanceHistory,
} from '../../types/TablesTypes/balanceHistory';
import TableHeader from '../../components/TableHeader/TableHeader';
import MenuOption from '../../components/MenuOption/MenuOption';
import { ReactComponent as LinkIcon } from '../../assets/images/Icons/link-icon.svg';
import balanceHistoryApi from '../../api/balanceHistory';
import useApi from '../../hooks/useApi';
import { numberWithCommas } from '../../utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import PaymentLinkMenu from '../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu';
import CreatePaymentLink from '../../components/ModalContent/CreatePaymentLink/CreatePaymentLink';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import BalanceMenu from '../../components/MenuOption/BalanceMenu/BalanceMenu';
import Filtered from '../../components/filterComponent/Filtered';
import { menuContent } from '../../utils/menuContent';
import StatusView from '../../components/StatusView/StatusView';
import FilteredUsage from '../../components/filterUsableComponent/FilteredUsage';
import ChargeBackMenu from '../../components/MenuOption/ChargeBackMenu/ChargeBackMenu';
import { ChargebackData, ColumnChargeback } from '../../types/TablesTypes/chargeBack';

function Chargeback() {
	const [modalIsOpen, setIsOpen] = useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	const paymentTypes: any[] = [];
	const [selectedPaymentType, setSelectedPaymentType] = useState<any>();
	const [inputs, setInputs] = useState<string>('');

	let selectedPaymentTypeOptions = paymentTypes.map(function (
		selectedPaymentType: any
	) {
		return {
			value: selectedPaymentType.code,
			label: selectedPaymentType.paymentLinkName,
		};
	});
	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputs(event.target.value);
	};
	const [tableRow, setTableRow] = useState<any[]>();
	const { id } = useParams<{ id: any }>();
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const [chargeback, setChargeback] = useState<any>();
	const [chargebackMetrics, setChargebackMetrics] = useState<any>();
	const { currency } = useSelector((state) => state.appSettingsReducer);

	const [direction, setDirection] = useState<string>('');
	const dispatch = useDispatch();

	//for filtering
	const [dropdown, setDropdown] = useState(false);
	const [fromDate, setFromDate] = useState<string>('');
	const [toDate, setToDate] = useState<string>('');
	const [paymentreference, setPaymentReference] = useState('');
	const [email, setEmail] = useState('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const clearHandler = () => {
		setFromDate('');
		setToDate('');
		setPaymentReference('');
		setEmail('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};
	const filteredArray = [
		{
			id: 1,
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [
				{ name: 'Won' },
				{ name: 'Lost' },
				{ name: 'Pending' },
				{ name: 'Awaiting Response' },
				{ name: 'Assessment' },
			],
		},
		{
			id: 2,
			name: 'Payment Reference',
			value: paymentreference,
			setValue: setPaymentReference,
		},
	];

	const fetchChargeback = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/dashboard/chargeback/all?status=${status}&reference=${paymentreference}&email=${email}&fromdate=${fromDate}&todate=${toDate}`
			);
			setChargeback(data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	const fetchChargebackMetric = async () => {
		dispatch(openLoader());
		try {
			const { data } = await client.get(
				`/subsidiary/dashboard/chargeback/overview`
			);

			console.log('data:', data);
			setChargebackMetrics(data);
			dispatch(closeLoader());
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};

	useEffect(() => {
		fetchChargeback();
	}, [bearer]);
	useEffect(() => {
		fetchChargebackMetric();
	}, []);

	const dataPaymentLinks = () => {
		const tempArr: ChargebackData[] = [];
		chargeback?.data?.items
			?.slice(0)
			.reverse()
			.forEach((balance: any, index: number) => {
				tempArr.push({
					date: moment(balance?.due_date).format('MMMM Do YYYY, h:mm a'),
					amount: `NGN ${numberWithCommas(balance?.amount)}`,
					id: balance?.id,
					status: (
						<>
							<StatusView
								status={balance?.status}
								green='Won'
								red='Lost'
								orange='Pending | New'
								indigo='Awaiting Response'
							/>
						</>
					),
					balance: balance?.balance
						? `NGN ${numberWithCommas(balance?.balance)}`
						: 'Nil',
					reference: balance?.transactionReference || 'Nil',
					action: <ChargeBackMenu cb={fetchChargeback} data={balance} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataPaymentLinks());
	}, [chargeback?.data?.items]);
	const Naira = () => {
		return (
			<div className={styles.grids}>
				<h3 className={styles.gridsh3}>
					This is the chargeback overview information
				</h3>
				<Grid container spacing={2}>
					<Grid item md={6} xs={12} lg={4}>
						<Card
							currency={currency}
							icon={BalanceIcon}
							amount={chargebackMetrics?.data?.overview?.thresholdPercent ?? 0.0}
							percentage={chargebackMetrics?.data?.overview?.thresholdPercent ?? 0.0}
							transactionType='Remaining of your threshold'
						/>
					</Grid>
					<Grid item md={6} xs={12} lg={4}>
						<Card
							currency={currency}
							icon={BlockIcon}
							amount={chargebackMetrics?.data?.overview?.chargebackValue ?? 0}
							transactionType='Chargeback Value'
						/>
					</Grid>
					<Grid item md={6} xs={12} lg={4}>
						<Card
							currency={currency}
							icon={BankIcon}
							amount={chargebackMetrics?.data?.overview?.chargebackCount ?? 0}
							count={true}
							transactionType='Chargeback Count'
						/>
					</Grid>
				</Grid>
				<div>
					<TableHeader
						pageName='Chargeback'
						dataLength={chargeback?.data?.totalItems}
						data={chargeback?.data?.items}
						value={email}
						setValue={setEmail}
						dropdown={dropdown}
						setDropdown={setDropdown}
						FilterComponent={
							<>
								<FilteredUsage
									setDropdown={setDropdown}
									setFromDate={setFromDate}
									setToDate={setToDate}
									setBearer={setBearer}
									clearHandler={clearHandler}
									filteredArray={filteredArray}
								/>
							</>
						}
					/>
					<Modal
						isOpen={modalIsOpen}
						onRequestClose={closeModal}
						contentLabel='Example Modal'
						style={customStyles}>
						<CreatePaymentLink
							closeModal={closeModal}
							fetchPaymentLink={fetchChargeback}
						/>
					</Modal>
					{customerDetails.loading ? (
						<div className={styles.loading} />
					) : (
						<PaginationTable
							data={tableRow ? tableRow : []}
							columns={ColumnChargeback ? ColumnChargeback : []}
							emptyPlaceHolder='You currently do not have any chargeback'
							total={chargeback?.data?.totalItems}
						/>
					)}
				</div>
			</div>
		);
	};
	return (
		<div className={styles.wrapper}>
			<div className={styles.tabswrapper}>
				<Tabs
					tabBarStyle={{ background: '#ffffff' }}
					className='tab-header'
					defaultActiveKey='1'>
					<Tabs.TabPane tab='Overview' key='1'>
						<Naira />
					</Tabs.TabPane>
					<Tabs.TabPane tab='Holding Balance' key='2' disabled></Tabs.TabPane>
				</Tabs>
			</div>
		</div>
	);
}

export default Chargeback;
