export const ToastSuccessStyles = {
	fontFamily: "'Kumbh Sans'",
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '24px',
	display: 'flex',
	alignItems: 'left',
	//   textAlign: "left",
	letterSpacing: '-0.011em',
	color: '#19943c',
	backgroundColor: '#EBF5F0',
	//   boxSizing: "border-box",
	borderRadius: '8px',
	justifyContent: 'left',
};

export const ToastErrorStyles = {
	fontFamily: "'Kumbh Sans'",
	fontStyle: 'normal',
	fontWeight: 600,
	fontSize: '16px',
	lineHeight: '24px',
	display: 'flex',
	alignItems: 'center',
	//   textAlign: "center",
	letterSpacing: '-0.011em',
	color: '#E00000',
	backgroundColor: '#FFECEC',
	//   boxSizing: "border-box",
	borderRadius: '8px',
	justifyContent: 'center',
};

export const inputStyle = {
	width: '100%',
	fontFamily: 'Kumbh Sans',
	fontStyle: 'normal',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	padding: '0.8rem',
	borderRadius: '10px',
	border: '1px solid #616161',
	outline: 'none',
};

export const constInputStyle = {
	width: '20%',
	fontFamily: 'Kumbh Sans',
	fontStyle: 'normal',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	padding: '1rem',
	borderRadius: '10px 0px 0px 10px',
	border: '1px solid #616161',
};

export const phoneInputStyle = {
	width: '80%',
	fontFamily: 'Kumbh Sans',
	fontStyle: 'normal',
	fontWeight: 500,
	fontSize: '16px',
	lineHeight: '24px',
	padding: '1rem',
	borderRadius: '0px 10px 10px 0px',
	border: '1px solid #616161',
};
