import React, { useEffect } from 'react';
import styles from './beneficiary.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/Icons/closenew.svg';
import { Divider } from 'antd';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import { getIdentifierState } from '../../redux/actions/Identifier/Identifier';

function Mobile({ closeModal, content, fn }: any) {
	const dispatch = useDispatch();
	console.log('content:', content);
	const { identifierState } = useSelector(
		(state) => state.getIdentifierStateReducer
	);

	const resp = () => {
		if (content.type === 'Bank Account') {
			return 'Bank';
		} else if (content.type === 'Payinvert Mobile') {
			return 'Mobile';
		} else if (content.type === 'Payinvert Merchant') {
			return 'Merchant';
		} else {
			return null;
		}
	};

	const clickHandler = () => {
		if (identifierState === 'Resolve') {
			dispatch(openLoader());

			client
				.post('/subsidiary/beneficiary/resolve', {
					type: resp(),
					accountNumber: content?.accountNumber,
					bankCode: 'Payinvert',
				})
				.then((data: any) => {
					dispatch(closeLoader());
					dispatch(getIdentifierState(''));
					fn();
					closeModal();
					dispatch(
						openToastAndSetContent({
							toastContent: data?.data?.message,
							toastStyles: ToastSuccessStyles,
						})
					);
				})
				.catch((error: any) => {
					const message = error?.response?.data?.message;
					dispatch(closeLoader());
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				});
		} else if (identifierState === 'Delete') {
			dispatch(openLoader());
			client
				.delete(`/subsidiary/beneficiary/delete/${content.id}`)
				.then((data: any) => {
					dispatch(closeLoader());
					closeModal();
					dispatch(getIdentifierState(''));
					fn();
					dispatch(
						openToastAndSetContent({
							toastContent: data?.data?.message,
							toastStyles: ToastSuccessStyles,
						})
					);
				})
				.catch((error: any) => {
					const message = error?.response?.data?.message;
					dispatch(closeLoader());
					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				});
		} else {
			return null;
		}
	};

	return (
		<div className={styles.modalwrapper}>
			<div className={styles.modalhead}>
				<h3 className={styles.modalheadh3}>{identifierState}</h3>
				<CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
			</div>
			<Divider style={{ margin: 0, padding: 0 }} />
			<div className={styles.modalcontent}>
				<p className={styles.sureparam}>
					ARE YOU SURE YOU WANT TO {identifierState}?
				</p>

				<div className={styles.modalFooter}>
					<button className={styles.cancel} onClick={closeModal}>
						Cancel
					</button>

					<button onClick={clickHandler} className={styles.fund}>
						{identifierState}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Mobile;
