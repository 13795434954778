import React, { useMemo, useEffect, useState } from 'react';
import styles from './Customers.module.scss';
import FundIcon from '../../../assets/images/funded.svg';
import {
	useTable,
	useSortBy,
	useFilters,
	usePagination,
	useGlobalFilter,
	useAsyncDebounce,
	Column,
	useRowSelect,
} from 'react-table';
import { ReactComponent as RightArrow } from '../../assets/IconsMerchant/next.svg';
import { ReactComponent as LeftArrowFaded } from '../../assets/IconsMerchant/previousfaded.svg';
// import { ReactComponent as EmptyTable } from "../../assets/images/empty-table.svg";
type DataColumn = {
	data: any;
	columns: any;
	emptyPlaceHolder: string;
	recent: boolean;
	value?: string;
	total?: number;
	// clickAction?: (id: string | number) => void;
};
const initialState = { hiddenColumns: ['id'] };
const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }: { indeterminate: any }, ref: any) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<>
				<input type='checkbox' ref={resolvedRef} {...rest} />
			</>
		);
	}
);
const PaginationTable = ({
	data,
	columns,
	emptyPlaceHolder,
	recent,
	value,
	total,
}: // clickAction,
Partial<DataColumn>) => {
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		gotoPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		pageOptions,
		pageCount,
		setPageSize,
		state: { pageIndex, pageSize, globalFilter },
		setGlobalFilter,
		prepareRow,
		selectedFlatRows,
	} = useTable(
		{
			columns,
			data,
			initialState: {
				sortBy: [
					{
						id: 'columnId',
						desc: false,
					},
				],
			},
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }: any) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }: any) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			]);
		}
	);
	const [searchVal, setSearchVal] = useState(globalFilter);
	const onChangeSearch = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 1000);
	useEffect(() => {
		setGlobalFilter(value);
		console.log('Value', value);
	}, [value]);
	return (
		<div className={styles.tableWrapper}>
			<table
				{...getTableProps()}
				style={{ width: '100%' }}
				className={styles.table}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps(column.getSortByToggleProps())}
									className={styles.tableHead}>
									{column.render('Header')}
									<span>
										{column.isSorted
											? !column.isSortedDesc
												? ' 🔽'
												: ' 🔼'
											: ''}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()} style={{ width: '100%' }}>
					{page?.map((row) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								// className={
								//   clickAction ? styles.tableBodyHover : styles.tableBody
								// }
								// onClick={
								//   clickAction ? () => clickAction(row.values.id) : () => null
								// }
							>
								{row.cells.map((cell) => {
									return (
										<td
											{...cell.getCellProps()}
											style={{
												padding: '20px',
												fontFamily: 'Sarabun',
												fontStyle: 'normal',
												fontWeight: 500,
												fontSize: '14px',
												lineHeight: '24px',
												letterSpacing: '-0.011em',
												color: '#424242',
												borderBottom: '1px solid #e5e5e5',
											}}>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			{data.length === 0 && (
				<div className={styles.emptyState}>
					<div className={styles.noTransaction}>{emptyPlaceHolder}</div>
					{/* <EmptyTable /> */}
				</div>
			)}
			{!recent ? (
				<div style={{ marginTop: '26px', background: 'white' }}>
					{' '}
					{/* Pagination */}
					{data.length === 0 ? null : (
						<div className={styles.paginatedSection}>
							<div>
								Page &nbsp;&nbsp;
								<input
									type='number'
									defaultValue={pageIndex + 1}
									onChange={(e) => {
										const page = e.target.value
											? Number(e.target.value) - 1
											: 0;
										gotoPage(page);
									}}
									className={styles.inputBox}
								/>{' '}
								of {total}
							</div>
							<div className={styles.pageleft}>
								{pageIndex + 1} - {pageCount} of {total}
								<div className={styles.arrowDirections}>
									<div
										onClick={() => previousPage()}
										style={{ paddingRight: '10px' }}>
										<div>
											{pageIndex + 1 === 1 ? (
												<div style={{ cursor: 'pointer' }}>
													<LeftArrowFaded />
												</div>
											) : (
												<div style={{ cursor: 'pointer' }}>
													<RightArrow
														style={{
															transform: 'rotate(180deg)',
															cursor: 'pointer',
														}}
													/>
												</div>
											)}
										</div>
									</div>

									{pageIndex + 1 === pageCount ? (
										<div onClick={() => nextPage()}>
											<div style={{ cursor: 'pointer' }}>
												<LeftArrowFaded
													style={{
														transform: 'rotate(180deg)',
														cursor: 'pointer',
													}}
												/>
											</div>
										</div>
									) : (
										<div onClick={() => nextPage()}>
											<div style={{ cursor: 'pointer' }}>
												<RightArrow />
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					)}
					{/* Pagination */}
					{/* Show items */}
					{/* Show items */}{' '}
				</div>
			) : null}
		</div>
	);
};
export default PaginationTable;
