import React, { useState, Fragment, useEffect } from "react";
import styles from "./TransferPayouts.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnPayouts, PayoutsData } from "../../types/TablesTypes/Payouts";
import TableHeader from "../../components/TableHeader/TableHeader";
import payoutsApi from "../../api/payouts";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import { customStyles } from "../../constants/modalStyles";
import Modal from "react-modal";
import InitiatePayout from "../../components/ModalContent/InitiatePayout/InitialPayout";
import client from "../../api/client";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";
import PayoutMenu from "../../components/MenuOption/PayoutMenu/PayoutMenu";
import Filtered from "../../components/filterComponent/Filtered";
import { menuContent } from "../../utils/menuContent";
import StatusView from "../../components/StatusView/StatusView";

const TransferPayouts = ({ payout, cb }: any) => {
  const dispatch = useDispatch();

  const [tableRow, setTableRow] = useState<any[]>();
  const [status, setStatus] = useState<string>("");
  const getPayoutsApi = useApi(payoutsApi.getPayouts);
  const [value, setValue] = useState("");
  const [dropdown, setDropdown] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  // const StatusView = ({ status, green, orange, red }: any) => {
  //   return (
  //     <div>
  //       {status === "Rejected" ? (
  //         <div className={styles.declined}>Rejected</div>
  //       ) : status === "Failed" ? (
  //         <div className={styles.declined}>Failed</div>
  //       ) : status === "Declined" ? (
  //         <div className={styles.declined}>Declined</div>
  //       ) : status === "Pending" ? (
  //         <div className={styles.processing}>Pending</div>
  //       ) : status === "Processing" ? (
  //         <div className={styles.processing}>Processing</div>
  //       ) : status === "Initiated" ? (
  //         <div className={styles.processing}>Initiated</div>
  //       ) : status === "Successful" ? (
  //         <div className={styles.completed}>Successful</div>
  //       ) : status === "Approved" ? (
  //         <div className={styles.completed}>Approved</div>
  //       ) : null}
  //     </div>
  //   );
  // };
  const dataPayout = () => {
    const tempArr: PayoutsData[] = [];
    payout?.items
      ?.slice(0)
      .reverse()
      .forEach((payout: any, index: number) => {
        tempArr.push({
          dateInitiated: moment(payout?.dateInitiated).format(
            "MMMM Do YYYY, h:mm a"
          ),
          recipientName: payout?.recipientName,
          accountNumber: payout?.accountNumber,
          currency: payout.debitCurrency,
          type: payout?.type,
          transferType: payout?.transferType,
          amount: `${payout?.debitCurrency} ${numberWithCommas(
            payout?.amount
          )}`,
          fee: `${payout?.debitCurrency} ${numberWithCommas(payout?.fee)}`,
          status: (
            <StatusView
              status={payout?.status}
              green={"Approved || Successful"}
              red={"Rejected || Failed || Declined"}
              orange={"Processing || Pending"}
              blue={"Awaiting Verification"}
              indigo="Awaiting Approval"
            />
          ),
          action: <PayoutMenu cb={cb} data={payout} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPayout());
  }, [payout?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Payouts"
        dataLength={payout?.page?.total}
        data={payout?.items}
        value={value}
        setValue={setValue}
        dropdown={dropdown}
        setDropdown={setDropdown}
        filtering={false}
        exporting={false}
      />

      {getPayoutsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnPayouts ? ColumnPayouts : []}
          emptyPlaceHolder="You currently do not have any payouts"
          total={payout?.page?.total}
        />
      )}
    </Fragment>
  );
};

export default TransferPayouts;
