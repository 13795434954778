import React from 'react';

export interface TransactionsData {
	date: string;
	email: string;
	amount: string;
	orderPaymentId: string;
	settlementAmount: string;
	payment: string;
	reference: string;
	status: React.ReactNode;
	action: React.ReactNode;
}

export const ColumnTransactions = [
	{
		Header: 'Date',
		accessor: 'date',
	},
	{
		Header: 'Email',
		accessor: 'email',
	},
	{
		Header: 'Amount',
		accessor: 'amount',
	},
	{
		Header: 'Settlement Amount',
		accessor: 'settlementAmount',
	},
	{
		Header: 'Payment method',
		accessor: 'payment',
	},
	{
		Header: 'Reference',
		accessor: 'reference',
	},
	{
		Header: 'Status',
		accessor: 'status',
	},
	{
		Header: 'Action',
		accessor: 'action',
	},
];
