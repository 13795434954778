import React, { useEffect } from 'react';
import styles from './Initiatepayout.module.scss';
import OutlineTextInput from '../../input/OutlineTextInput';
import client from '../../../api/client';
import { useDispatch, useSelector } from 'react-redux';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
    ToastErrorStyles,
    ToastSuccessStyles,
} from '../../../constants/toastStyles';
import Select from 'react-select';
import {
    closeLoader,
    openLoader,
} from '../../../redux/actions/loader/loaderActions';

const BeneficiaryPayout = ({ closeModal, fn, beneficiary, payoutCb }: any) => {
    const dispatch = useDispatch();
    const [banks, setBanks] = React.useState<any>([]);
    const { currencies, currency } = useSelector((state) => state?.appSettingsReducer)
    const [selectedBeneficiaryInput, setSelectedBeneficiaryInput] = React.useState<any>();
    const [selectedCurrencyInput, setSelectedCurrencyInput] = React.useState<any>({
        label: currency,
        value: currency
    });
    const [selectedBeneficiary, setSelectedBeneficiary] = React.useState<any>();
    const [beneficiaries, setBeneficiaries] = React.useState<any>([]);
    const [currenciesOption, setCurrenciesOption] = React.useState<any>([]);

    interface initTypes {
        accountNumber: number | undefined;
        description: string;
        amount: number | undefined;
    }

    const initProps: initTypes = {
        accountNumber: undefined,
        description: '',
        amount: undefined,
    };
    const handleSubmitBen = (e: any) => {
        e.preventDefault();
        initiatePayoutBen();
    };
    const [inputs, setInputs] = React.useState(initProps);

    const disableBenFunction = () => {
        if (inputs.amount && selectedBeneficiary?.accountNumber) {
            return true;
        }
    };

    const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        const inputName = event.target.name;
        setInputs((prevState: initTypes) => {
            return {
                ...prevState,
                [inputName]: newValue,
            };
        });
    };

    const payloadBankBen = () => {
        console.log({ selectedBeneficiary })
        return {
            bankCode: selectedBeneficiary?.bankCode ?? "payinvert",
            creditCurrency: selectedCurrencyInput.value,
            type: 'merchant',
            AccountName: selectedBeneficiary?.name,
            accountNumber: selectedBeneficiary?.accountNumber,
            amount: inputs.amount,
            description: inputs.description,
        }
    };
    const initiatePayoutBen = async () => {
        dispatch(openLoader());
        try {
            const data: any = await client.post('/subsidiary/payout/initiate', {
                payoutDetails: [payloadBankBen()]
            });
            if (payoutCb) return payoutCb(data?.data);
            const message = data?.data?.message;
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastSuccessStyles,
                    toastContent: message,
                })
            );
            closeModal();
            fn()
        } catch (error: any) {
            console.log({ error })
            const { message } = error?.response?.data || { message: "Something went wrong!" };
            dispatch(
                openToastAndSetContent({
                    toastStyles: ToastErrorStyles,
                    toastContent: message,
                })
            );
        }
        finally {
            dispatch(closeLoader());

        }
    };

    useEffect(() => {
        console.log({ beneficiary })
        const bene = beneficiary?.data?.items?.map((x: any) => ({ value: x.id, label: `${x.name} (${x.accountNumber})` }));
        console.log({ bene })
        if (bene && bene.length > 0) setBeneficiaries([...bene])
    }, [beneficiary])

    useEffect(() => {
        const bene = currencies?.map((x: any) => ({ value: x.shortName, label: `${x.shortName}` }));
        console.log({ bene })
        if (bene && bene.length > 0) setCurrenciesOption([...bene])
    }, [currencies])


    useEffect(() => {
        const bene = beneficiary?.data?.items.filter((x: any) => x.id == selectedBeneficiaryInput?.value)
        console.log({ selectedBeneficiaryInput, bene: bene[0] })
        if (bene && bene.length > 0) setSelectedBeneficiary(bene[0])
    }, [selectedBeneficiaryInput])

    return <>
        <div className={styles.businessForm}>
            <form onSubmit={handleSubmitBen} className={styles.form}>
                <OutlineTextInput
                    handleChange={updateProps}
                    inputName='amount'
                    inputLabel='Amount'
                    inputValue={inputs.amount}
                />
                <div className={styles.modalselect}>
                    <label className={styles.inputLabel}>Select Currency</label>
                    <Select
                        defaultValue={selectedCurrencyInput}
                        onChange={setSelectedCurrencyInput}
                        options={currenciesOption}
                        className={styles.select}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                                width: '100%',
                            }),
                        }}
                    />
                </div>
                <div className={styles.modalselect}>
                    <label className={styles.inputLabel}>Select Beneficiary</label>
                    <Select
                        // defaultValue={selectedType}
                        onChange={setSelectedBeneficiaryInput}
                        options={beneficiaries}
                        // styles={customStyles}
                        className={styles.select}
                        styles={{
                            // Fixes the overlapping problem of the component
                            menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                                width: '100%',
                            }),
                        }}
                    />
                </div>
                {selectedBeneficiary && <div>
                    <p> Bank Name: {selectedBeneficiary?.bankName}</p>
                    <p> Account Number: {selectedBeneficiary?.accountNumber}</p>
                    <p> Account Name: {selectedBeneficiary?.name}</p>
                </div>}
                {/* <div className={styles.listBenWrapper}>
                {beneficiary?.data?.items?.map((item: any) => (
                    <div
                        style={{
                            color: benelist === item.id ? 'white' : '',
                            background: benelist === item.id ? 'green' : '',
                        }}
                        onClick={() => {
                            setBeneDetails(item);
                            setBenelist(item.id);
                        }}
                        className={styles.listBen}>
                        {item.name}
                    </div>
                ))}
            </div> */}

                <OutlineTextInput
                    handleChange={updateProps}
                    inputName='description'
                    inputLabel='Description'
                    inputValue={inputs.description}
                // multiline={true}
                />
            </form>
        </div>
        <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
                Cancel
            </div>
            {disableBenFunction() ? (
                <div className={styles.deactivate} onClick={handleSubmitBen}>
                    Initiate payout
                </div>
            ) : (
                <div className={styles.notClickable}> Initiate payout</div>
            )}
        </div>
    </>
};

export default BeneficiaryPayout;
