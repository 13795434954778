import React from "react";
import styles from "./Role.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import SelectDropdown from "../../input/SelectDropdown";
import Select from "react-select";

const CreateRole = ({ closeModal, fetchPaymentLink, permissions }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    name: string;
    description: string;
  }

  const initProps: initTypes = {
    name: "",
    description: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.name && inputs.description && selectedId) {
      return true;
    }
  };

  const [selectedOption, setSelectedOption] = React.useState<any>();

  const selectedId = selectedOption?.map((item: any) => item.value);

  let permissionOptions = permissions?.map(function (permission: any) {
    console.log("permission", permission);
    return { value: permission?.id, label: permission?.name };
  });

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const createPaymentLink = async () => {
    try {
      const data: any = await client.post("subsidiary/dashboard/role/create", {
        ...inputs,
        permissions: selectedId,
      });

      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New role</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Role name"
            inputValue={inputs.name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />
          <div>
            <label className={styles.inputLabel}>Permissions</label>
            <Select
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={permissionOptions}
              isMulti
              // styles={customStyles}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              className={styles.select}
            />
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create role
          </div>
        ) : (
          <div className={styles.notClickable}>Create role</div>
        )}
      </div>
    </div>
  );
};

export default CreateRole;
