export function randnumber(length: number) {
  return Math.random().toString(16).substring(2, 2 + length)
}

export function validateEmail(email: string) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function validatePassword(password: string) {
  // Define the regex with the required conditions
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  return passwordRegex.test(password);
}

export function validatePasswordWithReason(password: string) {
  // 1. Check minimum length
  if (password.length < 8) {
    return 'Password must be at least 8 characters long.';
  }

  // 2. Check for lowercase letter
  if (!/[a-z]/.test(password)) {
    return 'Password must contain at least one lowercase letter.';
  }

  // 3. Check for uppercase letter
  if (!/[A-Z]/.test(password)) {
    return 'Password must contain at least one uppercase letter.';
  }

  // 4. Check for digit
  if (!/\d/.test(password)) {
    return 'Password must contain at least one digit.';
  }

  // 5. Check for special character
  // Adjust the character set as needed
  if (!/[@$!%*?&]/.test(password)) {
    return 'Password must contain at least one special character (@$!%*?&).';
  }

  // If all checks pass, password is valid
  return '';
}

export const updateFormProperties = (event: React.ChangeEvent<HTMLInputElement>, cb: any) => {
  var name = event.target.name;
  var value = event.target.value;
  var pp = { [name]: value, [name + "Error"]: '' }
  try {
    if ((name?.toLowerCase() == "email" || name?.toLowerCase() == "identifier") && !validateEmail(value)) throw new Error("Please enter a valid email address");
    if (name?.toLowerCase() == "password") {
      var error = validatePasswordWithReason(value);
      console.log({ error })
      if (error) throw new Error(error);
    }
    cb({ ...pp });
  }
  catch (e: any) {
    const message = e?.message;
    pp[name + "Error"] = message;
    cb({ ...pp });
  }
};


export function isOver18(dobString: string) {
  // Convert the date string to a Date object
  const dob = new Date(dobString);

  // Check if the provided dobString was a valid date
  if (isNaN(dob.getTime())) {
    // Invalid date
    return false;
  }

  // Get today's date
  const today = new Date();

  // Calculate the age in years
  let age = today.getFullYear() - dob.getFullYear();

  // Check if the birthday has occurred this year
  const monthDiff = today.getMonth() - dob.getMonth();
  const dayDiff = today.getDate() - dob.getDate();

  // If the user's birthday is still upcoming, subtract 1 year
  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
  }

  return age >= 18;
}