import React, { useState } from 'react';
import styles from './Signin.module.scss';
import logo from '../../../assets/images/Logos/paymentgateway.png';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import { ToastErrorStyles, ToastSuccessStyles } from '../../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import ForgotPasswordModal from '../../../components/ModalContent/ForgotPasswordModal/ForgotPasswordModal';
import Img from '../../../assets/IconsMerchant/landing.png';
import { onboardingState as onboardingfunc, onVerificationStateAction } from '../../../redux/actions/onboardingState/onbodardingState';
import { saveOnboardRoute } from '../../../redux/actions/onboardlevel/onboardLevelAction';
import { saveMeInfo } from '../../../redux/actions/me/meActions';
import showPwdImg from '../../../assets/images/Icons/show-password.svg';
import hidePwdImg from '../../../assets/images/Icons/hide-password.svg';
import { getProfileDetailsAction, signInAction, signInCompleteAction } from '../../../redux/actions/authentication/authentication';
import client from '../../../api/client';

const TwoFaSignIn = () => {
	const todaysDate = new Date();
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { subsidiaryInfo } = useSelector(
		(state: any) => state.subsidiaryInfoReducer
	);
	console.log({ subsidiaryInfo })
	const loginHash = subsidiaryInfo?.data?.loginHash?.loginHash;

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	interface initTypes {
		otp: string | undefined;
	}


	const initProps: initTypes = {
		otp: undefined
	};
	const [inputs, setInputs] = React.useState(initProps);

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputs((prevState: initTypes) => {
			return { ...prevState, [event.target.name]: event.target.value, };
		});
	};

	const getMe = async () => {
		try {
			dispatch(openLoader());
			dispatch(await getProfileDetailsAction((data: any, err: any) => {
				dispatch(closeLoader());
				if (err) throw new Error(err?.message)
				dispatch(saveMeInfo(data));
				dispatch(saveOnboardRoute(data?.routeToGetStarted));
				dispatch(onboardingfunc(data?.subsidiaryDetails?.subsidiaries[0]?.businessType));
				dispatch(onVerificationStateAction(data?.subsidiaryDetails?.subsidiaries[0]?.verificationStatus));
				dispatch(saveSubsidiaryInfo(data.data));
				history.push('/dashboard');
			}))
		} catch (error: any) {
			dispatch(closeLoader());
			const message = error?.message;
			dispatch(
				openToastAndSetContent({
					toastContent: message,
					toastStyles: ToastErrorStyles,
				})
			);
		}
	};

	const handleSubmit = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		const payload = {
			...inputs,
			email: subsidiaryInfo.email,
			subsidiaryId: subsidiaryInfo.id
		};
		dispatch(signInCompleteAction(payload, (data: any, err: any) => {
			dispatch(closeLoader());
			try {
				const { status, message, token } = data ?? {};
				if (err) throw new Error(err?.message)
				if (!token?.accessToken) throw new Error("Unable to complete sign in at this time")
				getMe().then();
			} catch (error: any) {
				dispatch(
					openToastAndSetContent({
						toastContent: error?.message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}))
	};

	const resendOtp = (e: React.SyntheticEvent) => {
		e.preventDefault();
		dispatch(openLoader());
		client
			.post('/subsidiary/dashboard/otp-resend', {
				identifier: subsidiaryInfo.email,
				loginHash: loginHash,
			})
			.then((data: any) => {
				dispatch(closeLoader());
				const { message } = data?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastSuccessStyles,
					})
				);
			})
			.catch((error: any) => {
				// <ErrorToast error={error} />;
				if (error.response) {
					dispatch(closeLoader());
					const message = error?.response?.data?.message;

					dispatch(
						openToastAndSetContent({
							toastContent: message,
							toastStyles: ToastErrorStyles,
						})
					);
				} else if (error.request) {
					dispatch(closeLoader());
				} else {
					dispatch(closeLoader());
				}
			});
	};

	return (
		<div className={styles.signWrapper}>
			<div className={styles.leftwrapper}>
				<div className={styles.image}>
					<img src={logo} alt='' />
				</div>
				<div className={styles.formwrapper}>
					<h1 className={styles.form_heading}>Complete sign in</h1>
					<p className={styles.form_p}>
						{subsidiaryInfo?.message ?? "Enter the One-Time Password (OTP) sent to your email"}
					</p>
					<div className={styles.businessForm}>
						<form onSubmit={handleSubmit} className={styles.form}>
							<OutlineTextInput
								style={{ marginTop: '10px' }}
								handleChange={updateProps}
								inputName='otp'
								inputLabel='One-Time Password (OTP)'
								inputValue={inputs.otp}
							/>

							<p className={styles.forgetPassword}>
								Sign into another account?{' '}
								<span
									onClick={() => setIsOpen(true)}
									className={styles.forgetPasswordspan}>
									Click here
								</span>
							</p>
							<button onClick={handleSubmit} className={styles.signInButton} disabled={!inputs.otp || inputs.otp?.length < 6}>
								Complete Sign In
							</button>
						</form>
					</div>
				</div>
				<p className={styles.createuser}>
					Didn't receive otp?{' '}
					<span
						onClick={resendOtp}
						className={styles.forgetPasswordspan}>
						Resend Otp
					</span>
				</p>
			</div>
			<div className={styles.rightwrapper}>
				<img className={styles.rightwrapper_img} src={Img} alt='' />
			</div>
		</div>

	);
};

export default TwoFaSignIn;
