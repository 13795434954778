import React, { useState, useEffect } from 'react';
import styles from './Refunddetails.module.scss';
import { useParams, useHistory } from 'react-router';
import axios from 'axios';
import client from '../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import Blacklist from '../../assets/images/Icons/blacklist.svg';

const RefundDetails = () => {
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const { id } = useParams<{ id: any }>();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	// useEffect(() => {
	// 	dispatch(openLoader());
	// 	setIsLoading(true);
	// 	client
	// 		.get(`subsidiary/dashboard/fetch/customers/orders?customers?id=${id}`)
	// 		.then((res: any) => {
	// 			setCustomerDetails(res?.data?.items[0]);
	// 			setIsLoading(false);
	// 			dispatch(closeLoader());
	// 		})
	// 		.catch((error) => {
	// 			dispatch(closeLoader());
	// 			setIsLoading(false);
	// 			if (error.response) {
	// 				const { message } = error?.response?.data;
	// 				dispatch(
	// 					openToastAndSetContent({
	// 						toastStyles: ToastErrorStyles,
	// 						toastContent: message,
	// 					})
	// 				);
	// 			} else if (error?.response?.data?.message === 'Please login again') {
	// 				sessionStorage.clear();
	// 				window.location.href = '/';
	// 			} else if (error.request) {
	// 				console.log(error.request);
	// 			} else {
	// 				console.log('Error', error.message);
	// 			}
	// 		});
	// }, []);

	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionsHeader}>
						<div className={styles.transactionsHeaderleft}>
							<h3>NGN 45,000.00</h3>
							<div className={styles.transactionsHeadercircle}>Successful</div>
						</div>
						<div className={styles.transactionsHeaderright}>
							<button>Refund customer again</button>
						</div>
					</div>

					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Refunds</h3>
						<Divider />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											balance after refund
										</div>
										<div className={styles.detailsKey}>NGN20,000</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>STATUS</div>
										<div className={styles.detailsKey}>Successful</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Date</div>
										<div className={styles.detailsKey}>
											10 July 2022 12:08pm
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>reason for refund</div>
										<div className={styles.detailsKey}>
											Items are out of stock
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>

					<div className={styles.detailBox}>
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>date/time</div>
										<div className={styles.detailsKey}>
											10 July 2022 12:08pm
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>CUSTOMER</div>
										<div className={styles.detailsKey}>james@gmail.com</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>card type</div>
										<div className={styles.detailsKey}>Visa</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>CARD NUMBER</div>
										<div className={styles.detailsKey}>**** **** **** 7648</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={3} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.blacklist}>
											<img src={Blacklist} alt='' />
											<p>Blacklist customer</p>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Payment Information</h3>
						<Divider />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>payment reference</div>
										<div className={styles.detailsKey}>
											Payinvert-ab95cf961f454669a4
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>TRANSACTION FEE</div>
										<div className={styles.detailsKey}>NGN 8,098.00</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>country/region</div>
										<div className={styles.detailsKey}>Nigeria</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Bank Name</div>
										<div className={styles.detailsKey}>Access Bank</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Payinvert reference
										</div>
										<div className={styles.detailsKey}>Payinvert-ab95c</div>
									</div>
								</Grid>{' '}
							</Grid>
						</div>
					</div>
					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Transaction Timeline</h3>
						<Divider />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											time spent making payment
										</div>
										<div
											style={{ color: '#19943c' }}
											className={styles.detailsKey}>
											1min 05secs
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											while making payment
										</div>
										<div
											style={{ color: '#EA5851' }}
											className={styles.detailsKey}>
											1 error
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>payment started</div>
										<div className={styles.detailsKey}>
											10 July 2022 12:08pm
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>payment completed</div>
										<div className={styles.detailsKey}>10 July 2022 1:08pm</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div
											style={{ color: '#2F80ED' }}
											className={styles.detailsValue}>
											Show breakdown
										</div>
									</div>
								</Grid>{' '}
							</Grid>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default RefundDetails;
