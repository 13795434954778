import React, { useEffect, useState } from 'react';
import styles from './Roles.module.scss';
import rolesApi from '../../api/roles';
import permissionsApi from '../../api/permissions';
import useApi from '../../hooks/useApi';
import { ReactComponent as RoleIcon } from '../../assets/images/Icons/role.svg';
import { ReactComponent as MarkIcon } from '../../assets/images/Icons/mark.svg';
import { ReactComponent as EditIcon } from '../../assets/images/Icons/edit.svg';
// import CreateRole from "../../components/ModalContent/Role/CreateRole";
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';

import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../constants/toastStyles';
import client from '../../api/client';

import EditRole from '../../components/ModalContent/Role/EditRole';
import CreateRole from '../../components/ModalContent/Role/CreateRole';
import TopBar from '../../components/topBar/TopBar';

const Roles = () => {
	const [roleDetails, setRoleDetails] = useState<any>([]);
	const [modalIsOpenCreate, setIsOpenCreate] = React.useState(false);
	function closeModalCreate() {
		setIsOpenCreate(false);
	}

	const dispatch = useDispatch();
	const getRolesApi = useApi(rolesApi.getRoles);
	const getPermissionsApi = useApi(permissionsApi.getPermissions);

	const fetchPermissions = async () => {
		getPermissionsApi.request();
	};

	const fetchRoles = async () => {
		getRolesApi.request();
	};

	useEffect(() => {
		fetchRoles();
		fetchPermissions();
	}, []);

	const rolesData = getRolesApi?.data?.data;
	const [selectedNav, setSelectedNav] = useState<any>();

	const permissionsData = getPermissionsApi?.data?.data;

	const viewRoleDetails = async (id: number) => {
		try {
			const data: any = await client.get(
				`/subsidiary/dashboard/role/details?roleid=${id}`
			);
			const message = data?.data?.message;
			setRoleDetails(data?.data?.data);
			// dispatch(
			//   openToastAndSetContent({
			//     toastStyles: ToastSuccessStyles,
			//     toastContent: message,
			//   })
			// );
		} catch (error: any) {
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastStyles: ToastErrorStyles,
						toastContent: message,
					})
				);
			}
		}
	};

	useEffect(() => {
		setSelectedNav(9);
		viewRoleDetails(9);
	}, []);

	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}

	const handleCreateRole = () => {};

	const handleEditRole = () => {};

	return (
		<>
			<div>
				<TopBar
					dataRef={rolesData}
					setIsOpenCreate={setIsOpenCreate}
					viewRoleDetails={viewRoleDetails}
					selectedNav={selectedNav}
					setSelectedNav={setSelectedNav}
				/>
			</div>
			<div className={styles.rolesBody}>
				<div className={styles.sidenav}>
					<div className={styles.sidenavHeader}>
						<div className={styles.rolesHeader}>{roleDetails?.name}</div>
						<div className={styles.rolesInfo}>{roleDetails?.description}</div>
					</div>
				</div>

				{!!roleDetails && (
					<div>
						<div className={styles.roles}>
							<div className={styles.rolesHeader}>Permissions</div>
							<div className={styles.permissionsList}>
								{!!roleDetails &&
									roleDetails?.permission?.map((permission: any) => (
										<div className={styles.permissionsItems}>
											<div className={styles.rolesLabel}>
												{permission?.permission}
											</div>
											<div className={styles.mark}>
												<MarkIcon />
											</div>
										</div>
									))}
								<div className={styles.mt1}></div>
							</div>
						</div>
						<div onClick={() => setIsOpen(true)} className={styles.editButton}>
							<EditIcon />
							<span>Edit role</span>
						</div>
						<Modal
							isOpen={modalIsOpen}
							onRequestClose={closeModal}
							contentLabel='Example Modal'
							style={customStyles}>
							{/* <CreateRole
                  closeModal={closeModal}
                  fetchPaymentLink={fetchRoles}
                /> */}
							<EditRole
								closeModal={closeModal}
								fetchPaymentLink={fetchRoles}
								permissions={roleDetails?.permission}
								roleDetails={roleDetails}
								allPermissions={permissionsData}
							/>
						</Modal>
					</div>
				)}
				<Modal
					isOpen={modalIsOpenCreate}
					onRequestClose={closeModalCreate}
					contentLabel='Example Modal'
					style={customStyles}>
					<CreateRole
						closeModal={closeModalCreate}
						fetchPaymentLink={fetchRoles}
						permissions={permissionsData}
					/>
				</Modal>
			</div>
		</>
	);
};

export default Roles;
