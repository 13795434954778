import React, { useState, useEffect, useRef } from 'react';
import styles from './personalInformation.module.scss';
import arrow from '../../../assets/IconsMerchant/Stroke 2.svg';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, Select, InputNumber } from 'antd';
import PlusIcon from '../../../assets/iconsOnboarding/uploadplus.svg';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import client, { formClient } from '../../../api/client';
import { useDispatch } from 'react-redux';
import { serialize } from 'object-to-formdata';
import { convertMb } from '../../../utils/convertMb';
import { saveOnboardLevelInfo } from '../../../redux/actions/onboardlevel/onboardLevelAction';
import { getOnboardingPercentagesAction } from '../../../redux/actions/authentication/authentication';
import { isOver18 } from '../../../utils/commonHelper';

const { Option } = Select;

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */
interface idType {
	id: number;
	name: string;
	countryId: number;
	bankCode: string;
	isMicrofinance: null | string;
	isMortgage: null | string;
}
interface initTypes {
	identification: any;
	passportPhoto: any;
	proofOfAddress: any;
}

const initProps: initTypes = {
	identification: '',
	passportPhoto: '',
	proofOfAddress: '',
};
function PersonalInformation() {
	const history = useHistory();
	const [idType, setIdType] = useState<idType[]>([]);
	const [personalInfo, setPersonalInfo] = useState<any>({});
	const [files, setFiles] = useState<any>({});
	const [fileErrors, setFileErrors] = useState<any>({});

	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const ref = useRef<any>(null);
	const refPassport = useRef<any>(null);
	const refProof = useRef<any>(null);

	const getIdType = () => {
		dispatch(openLoader());
		client.get(`/subsidiary/dashboard/onboarding/identification/types`)
			.then((data: any) => {
				console.log('datap:', data?.data);
				setIdType(data?.data?.data);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			});
	};
	const getPersonalInfo = () => {
		dispatch(openLoader());
		client.get(`/subsidiary/dashboard/onboarding/personal/information/view`)
			.then((data: any) => {
				console.log('dataf:', data?.data);
				setPersonalInfo(data?.data?.data);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			});
	};
	useEffect(() => {
		getIdType();
		getPersonalInfo();
	}, []);

	const filedDocument = () => {
		if ((files.identification, files.passportPhoto, files.proofOfAddress)) {
			return false;
		} else {
			return true;
		}
	};


	useEffect(() => {
		const dob = personalInfo?.dateOfBirth?.split('T')[0];
		const phone = personalInfo?.mobileNumber?.split('+234')[1];

		form.setFieldValue('bvn', personalInfo?.bvn);
		form.setFieldValue('firstname', personalInfo?.firstName);
		form.setFieldValue('lastname', personalInfo?.lastName);
		form.setFieldValue('gender', personalInfo?.gender);
		form.setFieldValue('dateofbirth', dob);
		form.setFieldValue('mobilenumber', phone);
		form.setFieldValue('idtype', personalInfo?.idType);
		form.setFieldValue('idnumber', personalInfo?.idNumber);
	}, [personalInfo]);

	const INITIALVALUES = {
		bvn: personalInfo?.bvn,
		firstname: personalInfo?.firstName,
		lastname: personalInfo?.lastName,
		gender: '',
		dateofbirth: '',
		mobilenumber: '',
		idtype: '',
		idnumber: '',
	};
	const updatedHandler = (values: any) => {
		console.log({ values, files })
		if (!files.proofOfAddress && !personalInfo?.proofOfAddress) return setFileErrors({ ...fileErrors, proofOfAddress: 'Proof of address is required' })
		if (!files.identification && !personalInfo?.identification) return setFileErrors({ ...fileErrors, identification: 'Identification file is required is required' })
		// if (!files.passportPhoto) return setFileErrors({ ...fileErrors, passportPhoto: 'Passport photograph is required' })
		dispatch(openLoader());
		const data = {
			...values,
			mobilenumber: `+234${values.mobilenumber}`,
			...files,
		};
		const formData = serialize(data);
		formClient
			.post(`/subsidiary/dashboard/onboarding/personal/information`, formData)
			.then(async (res: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: res.data.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				await getOnboardingPercentagesAction(dispatch);
				history.push('/dashboard/getStarted/onboarding');
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};

	const handleChange = (e: any) => {
		setFileErrors({ ...fileErrors, [e.target.name]: '' })
		setFiles((prevState: any) => {
			return {
				...prevState,
				[e.target.name]: e.target.files[0],
			};
		});
	};

	const ValidateDob = (_: any, value: any) => {
		return /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])/.test(value)
			? isOver18(value) ? Promise.resolve() : Promise.reject('User must be 18 years or above')
			: Promise.reject('date must be in this YYYY-MM-DD format');
	};

	const ValidateMobileNumber = (value: any) => {
		return /^\+?[1-9]\d{1,14}$/.test("+234" + value)
	};

	const CheckDocumentSource = (name: any) => {
		const file = (files[name] || personalInfo[name])
		console.log({ file })
		return !!file
	};

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperInner}>
				<div
					onClick={() => history.push('/dashboard/getStarted/onboarding')}
					className={styles.wrapperInnerLeft}>
					<img src={arrow} alt='' />
					<p>Get started</p>
				</div>
				<div className={styles.wrapperInnerRight}>
					<h2 className={styles.wrapperh2}>Primary Identity Information</h2>
					<p className={styles.wrapperp}>
						This helps us confirm your identity. We use the details you provide
						for contact and verification purposes only and it will not be visible to any one
						else on your team/business.
					</p>

					<Form
						form={form}
						initialValues={INITIALVALUES}
						layout='vertical'
						name='nest-messages'
						onFinish={updatedHandler}
						validateMessages={validateMessages}>
						<div className={styles.inputwrapper}>
							<Form.Item
								style={{ flex: 1 }}
								name='bvn'
								label='BVN (To get your BVN, dial *565*0# on your registered mobile number'
								rules={[
									{
										required: true,
									},
									{ min: 11 },
									{ max: 11 },
									{
										validator: (_, value) =>
											/[0-9]/.test(value)
												? Promise.resolve()
												: Promise.reject('bvn should contain 11 number'),
									},
								]}>
								<Input
									type='number'
									style={{ borderRadius: '7px' }}
									className={styles.input}
								/>
							</Form.Item>
							<div className={styles.flexInput}>
								<Form.Item
									style={{ flex: 1, marginRight: '16px' }}
									label='First name'
									name='firstname'
									rules={[
										{
											required: true,
										},
										{
											validator: (_, value) =>
												/^([A-Za-z])+$/.test(value)
													? Promise.resolve()
													: Promise.reject(
														'first name must only contain alphabet'
													),
										},
									]}>
									<Input
										style={{ borderRadius: '7px' }}
										className={styles.input}
									/>
								</Form.Item>

								<Form.Item
									style={{ flex: 1 }}
									label='Last name'
									name='lastname'
									rules={[
										{
											required: true,
										},
										{
											validator: (_, value) =>
												/^([A-Za-z])+$/.test(value)
													? Promise.resolve()
													: Promise.reject(
														'Last name must only contain alphabet'
													),
										},
									]}>
									<Input
										style={{ borderRadius: '7px' }}
										className={styles.input}
									/>
								</Form.Item>
							</div>
							<Form.Item
								style={{ flex: 1 }}
								name='gender'
								label='Gender'
								className='selectOptions'
								rules={[{ required: true }]}>
								<Select defaultValue='Male' placeholder='Gender' allowClear>
									<Option value='Male'>Male</Option>
									<Option value='Female'>Female</Option>
								</Select>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								label='Date of Birth'
								name='dateofbirth'
								rules={[
									{ required: true },
									{
										validator: ValidateDob
									},
								]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
									placeholder='YYYY-MM-DD'
									type='date'
								/>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								label='Phone number'
								name='mobilenumber'
								rules={[
									{
										required: true,
									},
									{ min: 9 },
									{ max: 16 },
									{
										validator: (_, value) =>
											// /[0-9]/.test(value)
											ValidateMobileNumber(value)
												? Promise.resolve()
												: Promise.reject('Enter a valid mobile number'),
									},
								]}>
								<Input
									style={{
										borderRadius: '7px',
										width: '100%',
										paddingTop: '10px',
									}}
									addonBefore='+234'
									className={styles.input}
									placeholder='8107061526'
								/>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								name='idtype'
								label='Identification document'
								className='selectOptions'
								rules={[{ required: true }]}>
								<Select allowClear>
									{idType.length &&
										idType?.map(({ name, id }, i) => (
											<Option key={i} value={id}>
												{name}
											</Option>
										))}
								</Select>
							</Form.Item>
							<Form.Item
								style={{ flex: 1 }}
								label='Identification number'
								name='idnumber'
								rules={[{ required: true }]}>
								<Input
									style={{ borderRadius: '7px' }}
									className={styles.input}
								/>
							</Form.Item>{' '}
							<h3 className={styles.formH3}>
								Please upload any of the following means of identification:
								international passport, driver’s license, national ID or voter’s
								ID. All files must be an image (.jpg, .jpeg, .png or .pdf) with
								max size of 5mb.
							</h3>
							<div>
								<h6 className={styles.labelHeader}>
									<span style={{ color: 'red', marginRight: '4px' }}>*</span>
									Identification
									<span style={{ color: 'red', marginLeft: '4px' }}>{fileErrors.identification}</span>
								</h6>
								<label
									style={{ cursor: 'pointer', width: '100%' }}
									onClick={() => ref.current.click()}>
									<div className={styles.uploadBox}>
										{CheckDocumentSource("identification") ? (
											<div>
												{files && files.identification ? (
													<div className={styles.previewwrap}>
														<img
															src={URL.createObjectURL(files?.identification)}
															className={styles.preview}
															alt='identification'
														/>
													</div>
												) : (
													<div className={styles.previewwrap}>
														{personalInfo?.identification !== '' ? (
															<img
																src={personalInfo?.identification}
																className={styles.preview}
																alt='identification'
															/>
														) : (
															''
														)}
													</div>
												)}
											</div>
										) : (
											<p>
												<span className={styles.uploadspan}>
													<img src={PlusIcon} alt='' />
												</span>
												Select file
											</p>
										)}
									</div>
								</label>
								<input
									type='file'
									id='identification'
									name='identification'
									accept='image/png,image/jpg,image/jpeg,application/pdf'
									onChange={handleChange}
									ref={ref}
									hidden
								/>

								{convertMb(files?.identification?.size) > 5 ? (
									<p style={{ color: 'red', textAlign: 'center' }}>
										Document should be 5bm or less
									</p>
								) : (
									<p style={{ color: 'black', textAlign: 'center' }}>
										{convertMb(files?.identification?.size) || ''}
									</p>
								)}
							</div>
							<div>
								<h6 className={styles.labelHeader}>
									<span style={{ color: 'red', marginRight: '4px' }}>*</span>
									Passport photograph
									<span style={{ color: 'red', marginLeft: '4px' }}>{fileErrors.passportPhoto}</span>
								</h6>
								<label
									style={{ cursor: 'pointer', width: '100%' }}
									onClick={() => refPassport.current.click()}>
									<div className={styles.uploadBox}>
										{CheckDocumentSource("passportPhoto") ? (
											<div>
												{files && files.passportPhoto ? (
													<div className={styles.previewwrap}>
														<img
															src={URL.createObjectURL(files.passportPhoto)}
															className={styles.preview}
															alt='passportPhoto'
														/>
													</div>
												) : (
													<div className={styles.previewwrap}>
														{personalInfo?.passportPhoto ? (
															<img
																src={personalInfo?.passportPhoto}
																className={styles.preview}
																alt='passportPhoto'
															/>
														) : (
															''
														)}
													</div>
												)}
											</div>
										) : (
											<p>
												<span className={styles.uploadspan}>
													<img src={PlusIcon} alt='' />
												</span>
												Select file
											</p>
										)}
									</div>
								</label>
								<input
									type='file'
									name='passportPhoto'
									id='passportPhoto'
									accept='image/png,image/jpg,image/jpeg,application/pdf'
									onChange={handleChange}
									ref={refPassport}
									hidden
								/>

								{convertMb(files?.passportPhoto?.size) > 5 ? (
									<p style={{ color: 'red', textAlign: 'center' }}>
										Document should be 5bm or less
									</p>
								) : (
									<p style={{ color: 'black', textAlign: 'center' }}>
										{convertMb(files?.passportPhoto?.size) || ''}
									</p>
								)}
							</div>
							<div>
								<h6 className={styles.labelHeader}>
									<span style={{ color: 'red', marginRight: '4px' }}>*</span>
									Proof of Address
									<span style={{ color: 'red', marginLeft: '4px' }}>{fileErrors.proofOfAddress}</span>
								</h6>
								<label
									style={{ cursor: 'pointer', width: '100%' }}
									onClick={() => refProof.current.click()}>
									<div className={styles.uploadBox}>
										{CheckDocumentSource("proofOfAddress") ? (
											<div>
												{files && files.proofOfAddress ? (
													<div className={styles.previewwrap}>
														<img
															src={URL.createObjectURL(files.proofOfAddress)}
															className={styles.preview}
															alt='proofOfAddress'
														/>
													</div>
												) : (
													<div className={styles.previewwrap}>
														{personalInfo?.proofOfAddress ? (
															<img
																src={personalInfo?.proofOfAddress}
																className={styles.preview}
																alt='proofOfAddress'
															/>
														) : (
															''
														)}
													</div>
												)}
											</div>
										) : (
											<p>
												<span className={styles.uploadspan}>
													<img src={PlusIcon} alt='' />
												</span>
												Select file
											</p>
										)}
									</div>
								</label>
								<input
									type='file'
									id='proofOfAddress'
									name='proofOfAddress'
									accept='image/png,image/jpg,image/jpeg,application/pdf'
									onChange={handleChange}
									ref={refProof}
									hidden
								/>

								{convertMb(files?.proofOfAddress?.size) > 5 ? (
									<p style={{ color: 'red', textAlign: 'center' }}>
										Document should be 5bm or less
									</p>
								) : (
									<p style={{ color: 'black', textAlign: 'center' }}>
										{convertMb(files?.proofOfAddress?.size) || ''}
									</p>
								)}
							</div>
							<Divider />
							<div className={styles.buttonflex}>
								<button
									onClick={() =>
										history.push('/dashboard/getStarted/onboarding')
									}
									className={styles.cancel}>
									Back
								</button>
								<Form.Item shouldUpdate>
									{() => (
										<button type='submit' className={styles.submitHandler}>
											Save
										</button>
									)}
								</Form.Item>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
}

export default PersonalInformation;
