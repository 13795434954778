import React, { useEffect, useState } from 'react';
import styles from './Balances.module.scss';
import Grid from '@material-ui/core/Grid';
import Card from '../../components/Card/OtherBalanceCard';
import BlockIcon from '../../assets/images/Icons/blockfunds.svg';
import BalanceIcon from '../../assets/images/Icons/newBalance.svg';
import BankIcon from '../../assets/images/Icons/newBank.svg';
import { ReactComponent as TopUp } from '../../assets/images/Icons/topup.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/Icons/view.svg';
import balanceApi from '../../api/walletBalance';
import useApi from '../../hooks/useApi';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import TopUpModal from '../../components/ModalContent/TopUpModal/TopUpModal';
import { useHistory } from 'react-router-dom';
import { Divider } from 'antd';
import { numberWithCommas } from '../../utils/formatNumber';
import { useSelector } from 'react-redux';
import Button from '../../components/button/Button';
import BankAccountTopModal from '../../components/ModalContent/BankAccountTopModal/BankAccountTopModal';

const Balances = () => {
	const WalletBalanceApi = useApi(balanceApi.getWalletBalance);
	const WalletMetricApi = useApi(balanceApi.getWalletHistory);
	const { currency } = useSelector((state) => state.appSettingsReducer);

	const [modalIsOpen, setIsModalOpen] = React.useState({
		isOpened: false,
		data: null
	});
	function closeModal() {
		setIsModalOpen({ isOpened: false, data: null });
	}
	function openModal(data: any) {
		setIsModalOpen({ isOpened: true, data });
	}
	const fetchWalletBalance = async () => {
		WalletBalanceApi.request();
	};
	const fetchMetricsBalance = async () => {
		WalletMetricApi.request();
	};
	useEffect(() => {
		fetchMetricsBalance();
	}, [currency]);
	useEffect(() => {
		fetchWalletBalance();
	}, []);

	const history = useHistory();

	const wallets = WalletBalanceApi?.data?.wallets ?? [];
	// const walletBalance = WalletBalanceApi?.data?.wallets.find((x: any) => x.currencyShortName == currency) ?? {};
	const walletMetrics = WalletMetricApi?.data?.data;

	// const availableBalance = walletBalance?.availableBalance;
	const chargebackMetrics = walletMetrics?.chargebacks;
	const refundMetrics = walletMetrics?.refunds;
	const complianceMetrics = walletMetrics?.nonCompliance;
	// const ledgerBalance = walletBalance?.ledgerBalance;
	// const bankName = walletBalance?.accountDetails?.bankName;
	// const accountNumber = walletBalance?.accountDetails?.accountNumber;

	return (
		<div>
			{/* <div className={styles.pageTop}>
				<div className={styles.actions}>
					<div className={styles.topUp} onClick={() => setIsOpen(true)}>
						<TopUp className={styles.topIcon} /> Top Up
					</div>
					<div
						className={styles.viewBalance}
						onClick={() => {
							history.push(
								`/dashboard/account/balances/balance-history/${walletId}`
							);
						}}>
						<ViewIcon className={styles.topIcon} />
						View balance history
					</div>
				</div>
			</div>

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel='Example Modal'
				style={customStyles}>
				<TopUpModal
					closeModal={closeModal}
					accountNumber={accountNumber}
					bankName={bankName}
				/>
			</Modal> */}
			{WalletBalanceApi.loading ? (
				<div className={styles.loading}>Loading...</div>
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item md={6} xs={12} lg={4}>
							{/* <Card
							icon={BalanceIcon}
							percentage={walletBalance && balanceChange}
							amount={walletBalance && availableBalance}
							transactionType='Dispute/ Chargeback'
						/> */}
							<Card
								currency={currency}
								icon={BalanceIcon}
								percentage={10}
								amount={chargebackMetrics}
								transactionType='Dispute/ Chargeback'
								link='/dashboard/chargeback'
								linkName='View chargebacks'
							/>
						</Grid>
						<Grid item md={6} xs={12} lg={4}>
							{/* <Card
							icon={BlockIcon}
							amount={walletBalance && ledgerBalance}
							transactionType='Refunds'
						/> */}
							<Card
								currency={currency}
								icon={BlockIcon}
								percentage={10}
								amount={refundMetrics}
								transactionType='Refunds'
								link='/dashboard/refunds'
								linkName='See all refunds'
							/>
						</Grid>
						<Grid item md={6} xs={12} lg={4}>
							{/* <Card
							icon={BankIcon}
							amount={accountNumber}
							transactionType='Non-compliance assessment'
						/> */}
							<Card
								currency={currency}
								icon={BankIcon}
								percentage={10}
								amount={complianceMetrics}
								transactionType='Non-compliance assessment'
								linkName='This is how much you’ve charged for defaulting on compliance rules.'
							/>
						</Grid>
					</Grid>

					{wallets.sort((a: any, b: any) => b.availableBalance - a.availableBalance).map((wallet: any) => (<div className={styles.detailBox}>
						<div className={styles.balanceCardHeader}>
							<h3 className={styles.detailh3}>{wallet.currencyShortName} Balance</h3>
							<p>{wallet.currencyShortName == "NGN" && <Button onClick={() => openModal(wallet)} disabled={wallet.currencyShortName != "NGN"} type='outlined' value='Top Up' className={styles.balanceCardHeaderButton} />}</p>
						</div>
						<Divider style={{ margin: 0, padding: 0 }} />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Account name</div>
										<div className={styles.detailsKey}>
											{wallet.accountDetails.accountName} ({wallet.accountDetails.accountNumber})
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Available balance</div>
										<div className={styles.detailsKey}>
											{wallet?.availableBalance && numberWithCommas(wallet?.availableBalance ?? 0)}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Ledger balance</div>
										<div className={styles.detailsKey}>
											{wallet?.ledgerBalance && numberWithCommas(wallet?.ledgerBalance ?? 0)}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>))}
					<Modal
						isOpen={modalIsOpen.isOpened}
						onRequestClose={closeModal}
						contentLabel='Topup Balance'
						style={customStyles}>
						<BankAccountTopModal data={modalIsOpen.data} closeModal={closeModal} />
					</Modal>
				</>
			)}
		</div>
	);
};

export default Balances;
