import React, { useState, Fragment, useEffect } from 'react';
import styles from './Settlements.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import {
	ColumnSettlements,
	SettlementsData,
} from '../../types/TablesTypes/Settlements';
import ExportButton from '../../components/ExportButton/ExportButton';
import StatusView from '../../components/StatusView/StatusView';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/drop-arrow.svg';
import settlementsApi from '../../api/settlements';
import useApi from '../../hooks/useApi';
import { numberWithCommas } from '../../utils/formatNumber';
import TableHeader from '../../components/TableHeader/TableHeader';
import {
	openLoader,
	closeLoader,
} from '../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { ToastErrorStyles } from '../../constants/toastStyles';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import client from '../../api/client';
import SettlementMenu from '../../components/MenuOption/SettlementMenu/SettlementMenu';
import FilteredUsage from '../../components/filterUsableComponent/FilteredUsage';

const Settlements = () => {
	const dispatch = useDispatch();
	const [value, setValue] = useState('');
	const [settlements, setSettlements] = useState<any>();
	const [tableRow, setTableRow] = useState<any[]>();
	const getSettlementsApi = useApi(settlementsApi.getSettlements);

	//for filtering
	const [dropdown, setDropdown] = useState(false);
	const [fromDate, setFromDate] = useState<string>('');
	const [toDate, setToDate] = useState<string>('');
	const [status, setStatus] = useState('');
	const [bearer, setBearer] = useState(false);
	const clearHandler = () => {
		setFromDate('');
		setToDate('');
		setStatus('');
		setDropdown(false);
		setBearer(true);
	};
	const filteredArray = [
		{
			id: 1,
			name: 'Status',
			value: status,
			setValue: setStatus,
			selective: [{ name: 'Completed' }, { name: 'Pending' }],
		},
	];

	const fetchSettlement = async () => {
		dispatch(openLoader());
		try {
			const { data }: any = await client.get(
				`/subsidiary/dashboard/settlement/paginated?settlementstatus=${status}&fromdate=${fromDate}&todate=${toDate}`
			);
			console.log({ data })
			setSettlements(data?.data);
			dispatch(closeLoader());
			setBearer(false);
		} catch (error: any) {
			dispatch(closeLoader());
			if (error?.response?.data?.message === 'Please login again') {
				sessionStorage.clear();
				window.location.href = '/';
			} else {
				const message = error?.response?.data?.message;

				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}
		}
	};
	useEffect(() => {
		fetchSettlement();
	}, [bearer]);

	const dataTransaction = () => {
		const tempArr: SettlementsData[] = [];
		settlements?.items
			?.slice(0)
			.reverse()
			.forEach((settlement: any, index: number) => {
				tempArr.push({
					subsidiaryName: settlement?.subsidiaryName,
					transactionAmount: `${settlement?.currency} ${numberWithCommas(
						settlement?.transactionAmount
					)}`,
					fee: `${settlement?.currency} ${numberWithCommas(settlement?.fee)}`,
					currency: `${(settlement?.currency).toUpperCase()}`,
					settlementStatus: (
						<StatusView
							status={settlement?.settlementStatus}
							green='Completed'
							orange='Pending'
						/>
					),
					action: <SettlementMenu data={settlement} />,
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataTransaction());
	}, [settlements?.items]);
	return (
		<Fragment>
			<TableHeader
				pageName='Settlement'
				dataLength={settlements?.total_items}
				data={settlements?.items}
				value={value}
				setValue={setValue}
				newButton={<div></div>}
				dropdown={dropdown}
				setDropdown={setDropdown}
				FilterComponent={
					<>
						<FilteredUsage
							setDropdown={setDropdown}
							setFromDate={setFromDate}
							setToDate={setToDate}
							setBearer={setBearer}
							clearHandler={clearHandler}
							filteredArray={filteredArray}
						/>
					</>
				}
			/>
			{getSettlementsApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					value={value}
					data={tableRow ? tableRow : []}
					columns={ColumnSettlements ? ColumnSettlements : []}
					emptyPlaceHolder='You currently do not have any settlements'
					total={settlements?.total_items}
				/>
			)}
		</Fragment>
	);
};

export default Settlements;
