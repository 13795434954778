export function getEnvironmentConfig(x: string) {
  const host = window.location.host;
  let env = getEnvironment();
  const basename = "REACT_APP_"
  return process.env[basename + env + "_" + x];
}

export function getEnvironment() {
  const host = window.location.host;
  let env = !host.includes("dev.payinvert") && !host.includes("localhost") ? "PROD" : "DEV";
  return env;
}