import React from "react";

export interface PaymentLinksData {
  id: number;
  name: string;
  paymentType: string;
  limit: string;
  amount: string;
  authOption: string;
  isActive: React.ReactNode;
  action: React.ReactNode;
  currency: string;
  dateCreated: string;
}

export const ColumnPaymentLinks = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Link Name",
    accessor: "name",
  },
  {
    Header: "Type",
    accessor: "paymentType",
  },
  {
    Header: "Limit",
    accessor: "limit",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  // {
  //   Header: "Auth Option",
  //   accessor: "authOption",
  // },  
  {
    Header: "Date Created",
    accessor: "dateCreated",
  },
  {
    Header: "Status",
    accessor: "isActive",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
