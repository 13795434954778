import * as React from 'react';
import Styles from './textfield.module.scss';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles, Select } from '@material-ui/core';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import { randnumber } from '../../utils/commonHelper';

interface TextInputProps {
	inputLabel: string;
	inputType?: string;
	inputShrink?: boolean;
	notRequired?: boolean;
	InputHelper?: string;
	inputSelect?: boolean;
	inputOption?: string[] | number[];
	multiline?: boolean;
	inputName: string;
	rows?: number;
	maxLength?: number;
	style?: React.CSSProperties;
	inputValue?: string | number;
	handleChange?: React.ChangeEventHandler<
		HTMLInputElement | HTMLTextAreaElement
	>;
	autoComplete?: string;
	focused?: boolean;
	disabled?: boolean;
	multiple?: boolean
}

const OutlineTextInput = ({
	inputLabel,
	inputType,
	notRequired,
	InputHelper,
	inputShrink,
	inputSelect = false,
	inputOption,
	multiline = false,
	inputName,
	inputValue,
	style,
	maxLength,
	rows,
	handleChange,
	autoComplete = 'on',
	focused = true,
	disabled = false,
	multiple = false
}: TextInputProps) => {
	const dispatch = useDispatch();
	interface State {
		amount: string;
		password: string;
		weight: string;
		weightRange: string;
		showPassword: boolean;
	}

	const useStyles = makeStyles({
		root: { color: '#19943c !important' },
		cssFocused: {
			fontFamily: "'Kumbh Sans'",
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '24px',
			display: 'flex',
			alignItems: 'center',
			letterSpacing: '-0.011em',
		},
		cssLabel: {
			color: '#19943c !important',
		},
		cssOutlinedInput: {
			borderRadius: '10px !important',
			'&$cssFocused $notchedOutline': {
				borderColor: `#616161 !important`,
				borderWidth: '0.8px !important',
				borderRadius: '10px',
			},
		},
		notchedOutline: {
			borderColor: '#616161 !important',
			borderRadius: '6px',
		},
		helperText: {
			fontFamily: 'Kumbh Sans !important',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '12px',
			lineHeight: '15px',
			color: 'red !important',
		},
	});
	const classes = useStyles();
	return (
		<TextField
			id={'outlined-basic' + randnumber(5)}
			label={inputLabel}
			focused={focused}
			onChange={handleChange}
			placeholder={inputLabel}
			select={inputSelect}
			style={style}
			required={notRequired ? false : true}
			classes={{ root: classes.root }}
			className={Styles.inputField}
			name={inputName}
			value={inputValue}
			disabled={disabled}

			variant='outlined'
			multiline={multiline}
			autoComplete={autoComplete}
			type={inputType ? inputType : ''}
			helperText={InputHelper ? InputHelper : ''}
			FormHelperTextProps={{ classes: { root: classes.helperText } }}
			rows={rows}
			inputProps={{
				maxLength: maxLength ?? (multiline ? 250 : 50),
				multiple: multiple
			}}
			InputLabelProps={{
				classes: {
					root: classes.cssLabel,
					focused: classes.cssFocused,
				},
				shrink: inputShrink,
			}}
			InputProps={{
				classes: {
					root: classes.cssOutlinedInput,
					focused: classes.cssFocused,
					notchedOutline: classes.notchedOutline,
				},
			}}>
			{inputOption?.map((option, index) => (
				<MenuItem value={option} key={option} className={Styles.options}>
					{option}
				</MenuItem>
			))}
		</TextField>
	);
};

export default OutlineTextInput;
