import React, { Dispatch, useEffect, MouseEventHandler } from 'react';
import styles from './Paymentmodal.module.scss';
import { ReactComponent as CloseIcon } from '../../../assets/images/Icons/close.svg';
import { numberWithCommas } from '../../../utils/formatNumber';
import moment from 'moment';

type PaymentProps = {
	closeModal: MouseEventHandler<SVGSVGElement>;
	reference: any;
};
const PaymentModal = ({ closeModal, reference }: PaymentProps) => {
	return (
		<div className={styles.modalBody}>
			<div className={styles.modalHeader}>
				<div className={styles.background}>
					<div className={styles.linkDetails}>Link details</div>
					<CloseIcon onClick={closeModal} />
				</div>
			</div>
			{reference && (
				<div className={styles.modalContent}>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Link Name</div>
						<div className={styles.content}>{reference?.name}</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Link Description</div>
						<div className={styles.content}>{reference?.description}</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Link Reference</div>
						<div className={styles.content}>{reference?.reference}</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Payment Type</div>
						<div className={styles.content}>{reference?.paymentType}</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Limit</div>
						<div className={styles.content}>{reference?.limit ?? "NO LIMIT"}</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Amount</div>
						<div className={styles.content}>
							{reference?.amount <= 0 ? "ANY AMOUNT" : `${reference?.currency} ${numberWithCommas(reference?.amount)}`}
						</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Status</div>
						<div className={styles.content}>
							{reference?.isActive ? (
								<div className={styles.active}>Active</div>
							) : (
								<div className={styles.inactive}>Inactive</div>
							)}
						</div>
					</div>
					<div className={styles.modalDetails}>
						<div className={styles.link}>Date Created</div>
						<div className={styles.content}>{moment(reference?.dateCreated).format("DD-MMM-YYYY hh:mm:ss a")}</div>
					</div>
					{/* <div className={styles.modalDetails}>
						<div className={styles.link}>Description</div>
						<div className={styles.content}>{reference?.description}</div>
					</div> */}
				</div>
			)}
		</div>
	);
};

export default PaymentModal;
