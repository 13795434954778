import React, { useState, useEffect } from 'react';
import styles from './ContactInformation.module.scss';
import arrow from '../../../assets/IconsMerchant/Stroke 2.svg';
import { useHistory } from 'react-router-dom';
import { Divider, Form, Input, InputNumber } from 'antd';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import {
	openLoader,
	closeLoader,
} from '../../../redux/actions/loader/loaderActions';
import {
	ToastErrorStyles,
	ToastSuccessStyles,
} from '../../../constants/toastStyles';
import client from '../../../api/client';
import { useDispatch } from 'react-redux';
import { saveOnboardLevelInfo } from '../../../redux/actions/onboardlevel/onboardLevelAction';
import { getOnboardingPercentagesAction } from '../../../redux/actions/authentication/authentication';

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
	required: '${label} is required!',
	types: {
		email: '${label} is not a valid email!',
		number: '${label} is not a valid number!',
	},
	number: {
		range: '${label} must be between ${min} and ${max}',
	},
};
/* eslint-enable no-template-curly-in-string */

function ContactInformation() {
	const history = useHistory();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const [personalInfo, setPersonalInfo] = useState<any>({});

	const INITIALVALUES = {
		firstName: '',
		lastName: '',
		mobileNumber: '',
	};

	const setFormFields = (values: any) => {
		form.setFieldValue("firstName", values.firstName);
		form.setFieldValue("lastName", values.lastName);
		var mobile = values?.mobileNumber?.replace("+234", "");
		form.setFieldValue("mobileNumber", mobile);
	}

	const getPersonalInfo = () => {
		dispatch(openLoader());
		client.get(`/subsidiary/dashboard/onboarding/personal/information/view`)
			.then((data: any) => {
				console.log('dataf:', data?.data);
				setFormFields(data?.data?.data ?? {});
				setPersonalInfo(data?.data?.data);
			})
			.catch((error: any) => {
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			}).finally(() => {
				dispatch(closeLoader());
			});
	};
	const updatedHandler = (values: any) => {
		console.log('hey:', values);
		dispatch(openLoader());
		client
			.post(`/subsidiary/dashboard/onboarding/contact/information`, {
				...values,
				mobileNumber: `+234${values.mobileNumber}`,
			})
			.then(async (res: any) => {
				dispatch(closeLoader());
				dispatch(
					openToastAndSetContent({
						toastContent: res.data.message,
						toastStyles: ToastSuccessStyles,
					})
				);
				await getOnboardingPercentagesAction(dispatch);
				history.push('/dashboard/getStarted/onboarding');
				window.location.reload();
			})
			.catch((error: any) => {
				dispatch(closeLoader());
				const { message } = error?.response?.data;
				dispatch(
					openToastAndSetContent({
						toastContent: message,
						toastStyles: ToastErrorStyles,
					})
				);
			});
	};
	useEffect(() => {
		getPersonalInfo();
	}, []);

	return (
		<div className={styles.wrapper}>
			<div className={styles.wrapperInner}>
				<div
					onClick={() => history.push('/dashboard/getStarted/onboarding')}
					className={styles.wrapperInnerLeft}>
					<img src={arrow} alt='' />
					<p>Get started</p>
				</div>
				<div className={styles.wrapperInnerRight}>
					<h2 className={styles.wrapperh2}>Contact Information</h2>
					<p className={styles.wrapperp}>
						Besides your business information, we need contact details of one
						person at the company. This can be the owner or anyone else we can
						contact when we need to.
					</p>
					<div className={styles.inputwrapper}>
						<Form
							form={form}
							initialValues={INITIALVALUES}
							layout='vertical'
							name='nest-messages'
							onFinish={updatedHandler}
							validateMessages={validateMessages}>
							<div className={styles.flexInput}>
								<Form.Item
									style={{ flex: 1, marginRight: '16px' }}
									label=''
									name='firstName'
									rules={[
										{
											required: true,
										},
										{
											validator: (_, value) =>
												/^([A-Za-z])+$/.test(value)
													? Promise.resolve()
													: Promise.reject(
														'First name must only contain alphabet'
													),
										},
									]}>
									<Input
										style={{ borderRadius: '7px' }}
										className={styles.input}
										placeholder='first name'
									/>
								</Form.Item>

								<Form.Item
									style={{ flex: 1 }}
									name='lastName'
									label=''
									rules={[
										{
											required: true,
										},
										{
											validator: (_, value) =>
												/^([A-Za-z])+$/.test(value)
													? Promise.resolve()
													: Promise.reject(
														'Last name must only contain alphabet'
													),
										},
									]}>
									<Input
										style={{ borderRadius: '7px' }}
										className={styles.input}
										placeholder='last name'
									/>
								</Form.Item>
							</div>

							<Form.Item
								style={{ flex: 1 }}
								name='mobileNumber'
								label=''
								rules={[
									{
										required: true,
									},
									{
										min: 10,
										message: 'Mobile number must contain 10 character',
									},
									{
										max: 10,
										message: 'Mobile number must contain 10 character',
									},
									{
										validator: (_, value) =>
											/[0-9]/.test(value)
												? Promise.resolve()
												: Promise.reject('mobile number should contain number'),
									},
								]}>
								<Input
									style={{ borderRadius: '7px', width: '100%', paddingTop: '10px' }}
									addonBefore='+234'
									className={styles.input}
									placeholder='8107061526'
								/>
							</Form.Item>

							<Divider />

							<div className={styles.buttonflex}>
								<button
									onClick={() =>
										history.push('/dashboard/getStarted/onboarding')
									}
									className={styles.cancel}>
									Cancel
								</button>
								<Form.Item shouldUpdate>
									{() => (
										<button
											type='submit'
											disabled={
												!form.isFieldsTouched(true) ||
												form
													.getFieldsError()
													.filter(({ errors }) => errors.length).length > 0
											}
											className={styles.submitHandler}>
											Save Changes
										</button>
									)}
								</Form.Item>
							</div>
						</Form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ContactInformation;
