import React, { useEffect, useState } from 'react';
import styles from './Tableheader.module.scss';
import ExportButton from '../../components/ExportButton/ExportButton';
import StatusView from '../../components/StatusView/StatusView';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/drop-arrow.svg';
import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableFilter from '../TableFilter/TableFilter';
import { Dropdown as MenuFunc } from 'antd';

type TableHeaderProps = {
	pageName?: string;
	dataLength?: number;
	data?: any;
	newButton?: React.ReactNode;
	value?: string | React.ReactNode;
	setValue?: React.Dispatch<React.SetStateAction<string>>;
	FilterComponent?: React.ReactNode;
	dropdown?: boolean;
	setDropdown?: React.Dispatch<React.SetStateAction<boolean>>;
	filtering?: boolean;
	exporting?: boolean;
	otherbutton?: boolean;
	otherbuttonTitle?: string;
	otherbuttonfunction?: any;
	isMenu?: boolean;
	menuComp?: any;
	fetchExportDataHandler?: Function
};
const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: 'white',
		},
		'& .MuiInput-underline:after': {
			// borderBottomColor: ‘yellow’,
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: '#19943c',
			},
			'&:hover fieldset': {
				borderColor: '#19943c',
			},
			'&.Mui-focused fieldset': {
				borderColor: '#19943c',
			},
		},
	},
})(TextField);
const TableHeader = ({
	pageName,
	dataLength,
	data,
	newButton,
	value,
	setValue,
	FilterComponent,
	dropdown,
	setDropdown,
	filtering = true,
	exporting = true,
	otherbutton = false,
	otherbuttonTitle,
	otherbuttonfunction,
	isMenu = false,
	menuComp,
	fetchExportDataHandler
}: TableHeaderProps) => {
	const [isDesktop, setDesktop] = useState(window.innerWidth > 800);
	const updateMedia = () => {
		setDesktop(window.innerWidth > 800);
	};
	useEffect(() => {
		window.addEventListener('resize', updateMedia);
		return () => window.removeEventListener('resize', updateMedia);
	});
	return (
		<div className={styles.pageTop}>
			{pageName && (
				<div className={styles.transactions}>
					{pageName}
					{/* - {dataLength} */}
					{/* - {dataLength} */}
				</div>
			)}
			<div className={styles.actions}>
				<div className={styles.actionButtons}>
					{/* <Box
						sx={{
							'& > :not(style)': isDesktop
								? {
										width: '18vw',
										height: '40px',
								  }
								: { width: '40vw', height: '30px' },
						}}>
						<CssTextField
							style={
								isDesktop
									? {
											marginTop: '0rem',
											padding: '0.4rem',
									  }
									: { marginTop: '0rem', padding: '0.2rem' }
							}
							id='input-with-icon-textfield'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
							placeholder='search...'
							variant='outlined'
							margin='normal'
							size='small'
							value={value}
							onChange={setValue ? (e) => setValue(e.target.value) : undefined}
						/>
					</Box> */}

					<>
						{filtering && (
							<TableFilter
								FilterComponent={FilterComponent}
								dropdown={dropdown}
								setDropdown={setDropdown}
							/>
						)}
						{exporting && (
							<ExportButton
								data={data}
								fileName={pageName}
								newButton={newButton}
								fetchDataHandler={fetchExportDataHandler}
							/>
						)}
						{otherbutton && (
							<button
								onClick={() => otherbuttonfunction(true)}
								className={styles.approveButton}>
								{otherbuttonTitle}
							</button>
						)}
						{isMenu && (
							<MenuFunc overlay={menuComp}>
								<button className={styles.approveButton}>
									{otherbuttonTitle}
								</button>
							</MenuFunc>
						)}
					</>
				</div>
				<div className={styles.headerButton}>{newButton}</div>
			</div>
		</div>
	);
};
export default TableHeader;
