import React, { useState, useEffect } from 'react';
import styles from './Transactiondetails.module.scss';
import { useParams, useHistory } from 'react-router';
import axios from 'axios';
import client from '../../api/client';
import {
	ToastSuccessStyles,
	ToastErrorStyles,
} from '../../constants/toastStyles';
import { useDispatch } from 'react-redux';
import { openToastAndSetContent } from '../../redux/actions/toast/toastActions';
import {
	closeLoader,
	openLoader,
} from '../../redux/actions/loader/loaderActions';
import moment from 'moment';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { numberWithCommas } from '../../utils/formatNumber';

const TransactionDetails = () => {
	const [customerDetails, setCustomerDetails] = useState<any>([]);
	const { id } = useParams<{ id: any }>();
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		dispatch(openLoader());
		setIsLoading(true);
		client.get(`subsidiary/dashboard/fetch/orders?paymentreference=${id}`)
			.then((res: any) => {
				res?.data?.data?.items?.length > 0 && setCustomerDetails(res?.data?.data?.items[0]);
				setIsLoading(false);
				dispatch(closeLoader());
			})
			.catch((error) => {
				dispatch(closeLoader());
				setIsLoading(false);
				if (error.response) {
					const { message } = error?.response?.data;
					dispatch(
						openToastAndSetContent({
							toastStyles: ToastErrorStyles,
							toastContent: message,
						})
					);
				} else if (error?.response?.data?.message === 'Please login again') {
					sessionStorage.clear();
					window.location.href = '/';
				} else if (error.request) {
					console.log(error.request);
				} else {
					console.log('Error', error.message);
				}
			});
	}, []);
	console.log({ customerDetails })
	return (
		<div>
			{isLoading ? (
				<div />
			) : (
				<>
					<div className={styles.transactionsHeader}>
						<div
							style={{
								color: '#211F01',
								display: 'inline',
								cursor: 'pointer',
								fontWeight: '800px',
							}}
							onClick={() => history.goBack()}>
							Transaction Details
						</div>
					</div>

					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Order details</h3>
						<Divider />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Order reference</div>
										<div className={styles.detailsKey}>
											{customerDetails?.orderReference}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Payment reference</div>
										<div className={styles.detailsKey}>
											{customerDetails?.paymentReference}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Payment response code
										</div>
										<div className={styles.detailsKey}>
											{customerDetails?.paymentResponseCode}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Payment response message
										</div>
										<div className={styles.detailsKey}>
											{!!customerDetails?.paymentResponseMessage
												? customerDetails?.paymentResponseMessage
												: 'N/A'}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>

					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Customer Details</h3>
						<Divider />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Customer Name</div>
										<div className={styles.detailsKey}>
											{customerDetails?.customerName}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Customer email</div>
										<div className={styles.detailsKey}>
											{customerDetails?.customerEmail}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Customer phone number
										</div>
										<div className={styles.detailsKey}>
											{customerDetails?.customerPhone}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={3}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Country</div>
										<div className={styles.detailsKey}>
											{customerDetails?.currency}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
					<div className={styles.detailBox}>
						<h3 className={styles.detailh3}>Payment Details</h3>
						<Divider />
						<div style={{ padding: '0 24px' }}>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Payment method</div>
										<div className={styles.detailsKey}>
											{customerDetails?.paymentTypeName}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Payment Instrument</div>
										<div className={styles.detailsKey}>
											{customerDetails?.accountNumber ?? customerDetails?.maskedPan}
										</div>
									</div>
								</Grid>								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Currency</div>
										<div className={styles.detailsKey}>
											{customerDetails?.currency}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={2} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Amount</div>
										<div className={styles.detailsKey}>
											{customerDetails?.currency}{' '}
											{numberWithCommas(customerDetails?.amount)}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Status</div>
										<div className={styles.detailsKey}>
											{customerDetails?.orderStatus}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Narration</div>
										<div className={styles.detailsKey}>
											{!!customerDetails?.narration
												? customerDetails?.narration
												: 'N/A'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Remark</div>
										<div className={styles.detailsKey}>
											{!!customerDetails?.remarks
												? customerDetails?.remarks
												: 'N/A'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Fee</div>
										<div className={styles.detailsKey}>
											{numberWithCommas(customerDetails?.fee)}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Merchant Fee</div>
										<div className={styles.detailsKey}>
											{numberWithCommas(customerDetails?.subsidiaryFee ?? 0)}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Customer Fee</div>
										<div className={styles.detailsKey}>
											{numberWithCommas(customerDetails?.customerFee ?? 0)}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={2}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Who bears fee?</div>
										<div className={styles.detailsKey}>
											{customerDetails?.whoBearsFee}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Date created</div>
										<div className={styles.detailsKey}>
											{moment(customerDetails?.dateCreated).format(
												'MMMM Do YYYY, h:mm a'
											)}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>Date updated</div>
										<div className={styles.detailsKey}>
											{customerDetails?.dateUpdated
												? moment(customerDetails?.dateUpdated).format(
													'MMMM Do YYYY, h:mm a'
												)
												: 'N/A'}
										</div>
									</div>
								</Grid>{' '}
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Date payment confirmed
										</div>
										<div className={styles.detailsKey}>
											{customerDetails?.datePaymentConfirmed
												? moment(
													customerDetails?.datePaymentConfirmed
												).format('MMMM Do YYYY, h:mm a')
												: 'N/A'}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} sm={6} md={4} lg={4}>
									<div className={styles.customerInfo}>
										<div className={styles.detailsValue}>
											Settlement Status
										</div>
										<div className={styles.detailsKey}>
											{customerDetails?.settlementStatus}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default TransactionDetails;
