import React, { useState, Fragment, useEffect } from 'react';
import styles from './User.module.scss';
import PaginationTable from '../../components/table/pagination-table';
import TableHeader from '../../components/TableHeader/TableHeader';
import {
	ColumnVirtualWallets,
	VirtualWalletsData,
} from '../../types/TablesTypes/VirtualWallet';
import ExportButton from '../../components/ExportButton/ExportButton';
import userApi from '../../api/users';
import useApi from '../../hooks/useApi';
import { ReactComponent as TopUp } from '../../assets/images/Icons/topup.svg';
import { ColumnUsers, UsersData } from '../../types/TablesTypes/Users';
import moment from 'moment';
import StatusView from '../../components/StatusView/StatusView';
import UserMenu from '../../components/MenuOption/UserMenu/UserMenu';
import Modal from 'react-modal';
import { customStyles } from '../../constants/modalStyles';
import AddUserModal from '../../components/ModalContent/AddUserModal/AddUserModal';

const User = () => {
	const [tableRow, setTableRow] = useState<any[]>();
	const [value, setValue] = useState('');
	const getUsersApi = useApi(userApi.getUsers);
	const [modalIsOpenBulk, setIsOpenBulk] = React.useState(false);

	function closeModalBulk() {
		setIsOpenBulk(false);
		getUsersApi.request();
	}

	useEffect(() => {
		getUsersApi.request();
	}, []);

	const dataPaymentLinks = () => {
		const tempArr: UsersData[] = [];
		getUsersApi?.data?.users
			.forEach((user: any, index: number) => {
				tempArr.push({
					emailAddress: user.emailAddress,
					fullName: `${user.firstName} ${user.lastName}`,
					mobileNumber: user.mobileNumber,
					role: user.role,
					status: (
						<StatusView
							status={user.status}
							green='Active'
							red='Inactive'
							orange='New'
						/>
					),
					userId: user.userId,
					addedByUserName: user.addedByUserName,
					dateCreated: moment(user?.dateAdded).format('MMMM Do YYYY, h:mm a'),
					action: < UserMenu cbfn={getUsersApi.request} data={user} />
				});
			});
		return tempArr;
	};

	useEffect(() => {
		setTableRow(dataPaymentLinks());
	}, [getUsersApi?.data?.users]);
	return (
		<Fragment>
			<TableHeader
				pageName='Users'
				dataLength={getUsersApi?.data?.users?.length ?? 0}
				data={getUsersApi?.data?.users}
				value={value}
				setValue={setValue}
				filtering={true}
				newButton={
					<div className={styles.createLink} onClick={() => setIsOpenBulk(true)}>
						<TopUp className={styles.link} />
						Create New User
					</div>
				}
			/>
			{getUsersApi.loading ? (
				<div className={styles.loading} />
			) : (
				<PaginationTable
					data={tableRow ? tableRow : []}
					columns={ColumnUsers ? ColumnUsers : []}
					emptyPlaceHolder='You currently do not have any user'
					total={getUsersApi?.data?.totalItems}
					value={value}
				/>
			)}
			{/* //MODAL FOR BULK */}
			<Modal
				isOpen={modalIsOpenBulk}
				onRequestClose={closeModalBulk}
				contentLabel='Example Modal'
				style={customStyles}>
				<AddUserModal closeModal={closeModalBulk} />
			</Modal>
		</Fragment>
	);
};

export default User;
