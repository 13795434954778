// import React from 'react';
// import styles from './Forgotpassword.module.scss';
// import Logo from '../../../assets/images/Logos/paymentgateway.png';
// import { ReactComponent as SignInIcon } from '../../../assets/images/Icons/signin.svg';
// import OutlineTextInput from '../../../components/input/OutlineTextInput';
// import {
// 	openLoader,
// 	closeLoader,
// } from '../../../redux/actions/loader/loaderActions';
// import { useDispatch } from 'react-redux';
// import { useHistory, useParams } from 'react-router-dom';
// import client from '../../../api/client';
// import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
// import {
// 	ToastErrorStyles,
// 	ToastSuccessStyles,
// } from '../../../constants/toastStyles';

// const ForgotPassword = () => {
// 	const todaysDate = new Date();
// 	const dispatch = useDispatch();
// 	const history = useHistory();
// 	const { email } = useParams<{ email: string }>();

// 	console.log('email', email);

// 	const [modalIsOpen, setIsOpen] = React.useState(false);
// 	function closeModal() {
// 		setIsOpen(false);
// 	}
// 	interface initTypes {
// 		otp: string;
// 		newPassword: string;
// 	}

// 	const initProps: initTypes = {
// 		otp: '',
// 		newPassword: '',
// 	};
// 	const [inputs, setInputs] = React.useState(initProps);
// 	let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
// 	const disabledFunction = () => {
// 		if (
// 			inputs.otp === '' ||
// 			inputs.newPassword === '' ||
// 			!regex.test(inputs?.newPassword)
// 		) {
// 			return true;
// 		} else {
// 			return false;
// 		}
// 	};

// 	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
// 		const newValue = event.target.value;
// 		const inputName = event.target.name;
// 		setInputs((prevState: initTypes) => {
// 			return {
// 				...prevState,
// 				[inputName]: newValue,
// 			};
// 		});
// 	};

// 	const handleSubmit = async (e: any) => {
// 		e.preventDefault();
// 		dispatch(openLoader());
// 		try {
// 			const data: any = await client.post(
// 				'/subsidiary/dashboard/complete/forgot-password',
// 				{
// 					...inputs,
// 					identifier: email,
// 				}
// 			);

// 			const message = data?.data?.message;
// 			dispatch(closeLoader());
// 			history.push(`/`);
// 			dispatch(
// 				openToastAndSetContent({
// 					toastStyles: ToastSuccessStyles,
// 					toastContent: message,
// 				})
// 			);
// 		} catch (error: any) {
// 			dispatch(closeLoader());
// 			const { message } = error?.response?.data;
// 			dispatch(
// 				openToastAndSetContent({
// 					toastStyles: ToastErrorStyles,
// 					toastContent: message,
// 				})
// 			);
// 		}
// 	};

// 	return (
// 		<div className={styles.signInBody}>
// 			<div onClick={() => history.push('/')} className={styles.logo}>
// 				<img src={Logo} alt='pgw' />
// 			</div>
// 			<div className={styles.signinForm}>
// 				<div className={styles.signIn}>Password</div>
// 				<div className={styles.businessForm}>
// 					<form onSubmit={handleSubmit} className={styles.form}>
// 						<OutlineTextInput
// 							handleChange={updateProps}
// 							inputName='otp'
// 							inputLabel='OTP'
// 							inputValue={inputs.otp}
// 						/>
// 						<OutlineTextInput
// 							handleChange={updateProps}
// 							inputName='newPassword'
// 							inputLabel='New Password'
// 							inputType='password'
// 							inputValue={inputs.newPassword}
// 						/>

// 						<button
// 							disabled={disabledFunction()}
// 							onClick={handleSubmit}
// 							className={styles.signInButton}>
// 							<SignInIcon />
// 							<span>Reset Password</span>
// 						</button>
// 					</form>
// 				</div>
// 			</div>
// 		</div>
// 	);
// };

// export default ForgotPassword;


import React, { useState } from 'react';
import styles from './Forgotpassword.module.scss';
import logo from '../../../assets/images/Logos/paymentgateway.png';
import OutlineTextInput from '../../../components/input/OutlineTextInput';
import { closeLoader, openLoader } from '../../../redux/actions/loader/loaderActions';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { openToastAndSetContent } from '../../../redux/actions/toast/toastActions';
import { saveSubsidiaryInfo } from '../../../redux/actions/subsidiaryInfo/subsidiaryInfoActions';
import { ToastErrorStyles, ToastSuccessStyles } from '../../../constants/toastStyles';
import Modal from 'react-modal';
import { customStyles } from '../../../constants/modalStyles';
import ForgotPasswordModal from '../../../components/ModalContent/ForgotPasswordModal/ForgotPasswordModal';
import Img from '../../../assets/IconsMerchant/landing.png';
import { onboardingState as onboardingfunc, onVerificationStateAction } from '../../../redux/actions/onboardingState/onbodardingState';
import { saveOnboardRoute } from '../../../redux/actions/onboardlevel/onboardLevelAction';
import { saveMeInfo } from '../../../redux/actions/me/meActions';
import showPwdImg from '../../../assets/images/Icons/show-password.svg';
import hidePwdImg from '../../../assets/images/Icons/hide-password.svg';
import { getProfileDetailsAction, signInAction } from '../../../redux/actions/authentication/authentication';
import client from '../../../api/client';

const SignIn = () => {
	const todaysDate = new Date();
	const [isRevealPwd, setIsRevealPwd] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { email } = useParams<{ email: string }>();
	const [modalIsOpen, setIsOpen] = React.useState(false);
	function closeModal() {
		setIsOpen(false);
	}
	interface initTypes {
		email: string;
		otp: string;
		newPassword?: string;
		confirmPassword?: string;
	}

	const initProps: initTypes = {
		email: email,
		newPassword: '',
		otp: '',
		confirmPassword: ''
	};
	const [inputs, setInputs] = React.useState(initProps);

	const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputs((prevState: initTypes) => {
			return { ...prevState, [event.target.name]: event.target.value, };
		});
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		dispatch(openLoader());
		try {
			const data: any = await client.post(
				'/subsidiary/dashboard/complete/forgot-password',
				{
					...inputs,
					newPassword: btoa(inputs.newPassword ?? ""),
					confirmPassword: btoa(inputs.confirmPassword ?? ""),
					identifier: email,
				}
			);

			const message = data?.data?.message;
			dispatch(closeLoader());
			history.push(`/`);
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastSuccessStyles,
					toastContent: message,
				})
			);
		} catch (error: any) {
			dispatch(closeLoader());
			const { message } = error?.response?.data;
			dispatch(
				openToastAndSetContent({
					toastStyles: ToastErrorStyles,
					toastContent: message,
				})
			);
		}
	};

	return (
		<div className={styles.signWrapper}>
			<div className={styles.leftwrapper}>
				<div className={styles.image}>
					<img src={logo} alt='' />
				</div>
				<div className={styles.formwrapper}>
					<h1 className={styles.form_heading}>Reset Account Password</h1>
					<div className={styles.businessForm}>
						<p className={styles.form_p}>
							Enter the One-Time Password (OTP) and your new password for your account <span style={{ fontWeight: 'bold' }}>{email}</span> below
						</p>
						<form onSubmit={handleSubmit} className={styles.form}>
							<OutlineTextInput
								style={{ marginTop: '10px' }}
								handleChange={updateProps}
								inputName='otp'
								inputLabel='One-Time Password (OTP)'
								inputValue={inputs.otp}
							/>

							<div className={styles.passwordInp}>
								<OutlineTextInput
									style={{ marginTop: '20px', width: '100%' }}
									handleChange={updateProps}
									inputName='newPassword'
									inputLabel='Password'
									inputType={isRevealPwd ? 'text' : 'password'}
									inputValue={inputs.newPassword}
								/>

								<img
									alt='/'
									className={styles.inputImg}
									title={isRevealPwd ? 'Hide password' : 'Show password'}
									src={isRevealPwd ? hidePwdImg : showPwdImg}
									onClick={() => setIsRevealPwd((prevState) => !prevState)}
								/>
							</div>

							<div className={styles.passwordInp}>
								<OutlineTextInput
									style={{ marginTop: '20px', width: '100%' }}
									handleChange={updateProps}
									inputName='confirmPassword'
									inputLabel='Confirm Password'
									inputType={isRevealPwd ? 'text' : 'password'}
									inputValue={inputs.confirmPassword}
								/>

								<img
									alt='/'
									className={styles.inputImg}
									title={isRevealPwd ? 'Hide password' : 'Show password'}
									src={isRevealPwd ? hidePwdImg : showPwdImg}
									onClick={() => setIsRevealPwd((prevState) => !prevState)}
								/>
							</div>

							<p className={styles.forgetPassword}>
								<span
									onClick={() => window.location.assign('/')}
									className={styles.forgetPasswordspan}>
									Login to your account
								</span>
							</p>
							<button onClick={handleSubmit} className={styles.signInButton} disabled={!email || !inputs.otp || !inputs.newPassword || !inputs.confirmPassword || (inputs.newPassword != inputs.confirmPassword)}>
								Reset Password
							</button>
						</form>
					</div>
				</div>
			</div>
			<div className={styles.rightwrapper}>
				<img className={styles.rightwrapper_img} src={Img} alt='' />
			</div>
		</div>

	);
};

export default SignIn;
