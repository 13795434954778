import styles from "./Onboarding.module.scss";
import { useHistory } from "react-router-dom";
import arrow from "../../../assets/IconsMerchant/Stroke 2.svg";
import { ReactComponent as User } from "../../../assets/iconsOnboarding/u_users-alt.svg";
import { ReactComponent as Kyc } from "../../../assets/iconsOnboarding/u_file-alt.svg";
import { ReactComponent as Information } from "../../../assets/iconsOnboarding/u_building (1).svg";
import { ReactComponent as Bank } from "../../../assets/iconsOnboarding/u_money-stack.svg";
import { ReactComponent as Term } from "../../../assets/iconsOnboarding/u_file-edit-alt.svg";
import { ReactComponent as Personal } from "../../../assets/iconsOnboarding/u_user-circle.svg";
import { useSelector } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { openLoader, closeLoader, } from "../../../redux/actions/loader/loaderActions";
import { ToastErrorStyles, ToastSuccessStyles, } from "../../../constants/toastStyles";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { onboardingState as onboardingfunc, onVerificationStateAction, } from "../../../redux/actions/onboardingState/onbodardingState";
import { saveOnboardRoute, } from "../../../redux/actions/onboardlevel/onboardLevelAction";
import { changeNavbar } from "../../../redux/actions/navbar/navbarActions";
import { useEffect } from "react";
import { completeOnboardingStepsAction, getProfileDetailsAction } from "../../../redux/actions/authentication/authentication";

function Onboarding() {
  const history = useHistory();
  const dispatch = useDispatch();
  const res = useSelector((state) => state?.onboardLevelInfoReducer?.onboardLevelInfo);
  const { onboardingState, onVerificationState } = useSelector((state) => state.onboardingStateReducer);
  const { onBoardRoute } = useSelector((state) => state.onboardLevelInfoReducer);
  const { meInfo } = useSelector((state) => state.meReducer);
  const defaultbusiness = meInfo?.subsidiaryDetails?.subsidiaries.find((x: any) => x.isDefault);
  const companytype = defaultbusiness?.businessType;
  // console.log({ onboardingState, meInfo, companytype, onVerificationState })

  const trigger = () => {
    const status = ["Pending-Approval", "Active"]
    if (status.includes(defaultbusiness?.status)) return { hideRequestToGoLive: true };
    else if (((res?.data?.personalInformation === 100 && onboardingState == "Individual") || (res?.data?.businessCompliance === 100 && onboardingState == "Company"))
      && res?.data?.businessInformation === 100
      && res?.data?.bankInformation === 100
      && res?.data?.termsAndCondition === 100 && !status.includes(onVerificationState)
    ) {
      return { allowRequestGoLove: true };
    } else {
      return {

      };
    }
  };

  useEffect(() => {
    dispatch(onboardingfunc(companytype));
  }, [companytype])
  const registeredData = [
    // {
    // 	title: 'Verify your email address',
    // 	desc: 'Click on the verification link we sent to your email address. You can resend the link if you did not get it.',
    // 	percentage: '0%',
    // 	icon: <Verify />,
    // 	resend: true,
    // },
    // {
    //   title: "Contact Information",
    //   desc: "Please provide details for a contact person if we need to get in touch.",
    //   percentage: "",
    //   icon: <User />,
    //   link: "/dashboard/getStarted/contact-Information",
    // },
    {
      title: "Primary Identity Information",
      desc: "Please provide a few details to help us verify your identity.",
      percentage: `${res?.data?.personalInformation ?? 0}%`,
      icon: <Personal />,
      link: "/dashboard/getStarted/personal-Information",
    },
    {
      title: "Business registration (KYC)",
      desc: "Please provide your business’ registration documents.",
      percentage: `${res?.data?.businessCompliance || 0.0}%`,
      icon: <Kyc />,
      link: "/dashboard/getStarted/business-Registration",
    },
    {
      title: "Business information",
      desc: "Please provide a few details to help us verify your identity.",
      percentage: `${res?.data?.businessInformation ?? 0}%`,
      icon: <Information />,
      link: "/dashboard/getStarted/business-Information",
    },
    {
      title: "Bank details",
      desc: "Please provide bank details of your preferred settlement account.",
      percentage: `${res?.data?.bankInformation ?? 0}%`,
      icon: <Bank />,
      link: "/dashboard/getStarted/bank-details",
    },
    {
      title: "Terms and conditions",
      desc: "Read and agree to our terms and conditions of use.",
      percentage: `${res?.data?.termsAndCondition ?? 0}%`,
      icon: <Term />,
      link: "/dashboard/getStarted/terms",
    },
    // {
    // 	title: 'Take dashboard tour (optional)',
    // 	desc: 'Learn about the awesome things you can do on your new dashboard.',
    // 	percentage: '0%',
    // 	icon: <Take />,
    // 	link: '/dashboard/getStarted/tour',
    // },
  ];

  const personalData = [
    // {
    // 	title: 'Verify your email address',
    // 	desc: 'Click on the verification link we sent to your email address. You can resend the link if you did not get it.',
    // 	percentage: '0%',
    // 	icon: <Verify />,
    // 	// resend: true,
    // },
    {
      title: "Personal Information",
      desc: "Please provide a few details to help us verify your identity.",
      percentage: `${res?.data?.personalInformation ?? 0}%`,
      icon: <Personal />,
      link: "/dashboard/getStarted/personal-Information",
    },
    {
      title: "Business information",
      desc: "Please provide a few details to help us verify your identity.",
      percentage: `${res?.data?.businessInformation ?? 0}%`,
      icon: <Information />,
      link: "/dashboard/getStarted/business-Information",
    },
    {
      title: "Bank details",
      desc: "Please provide bank details of your preferred settlement account.",
      percentage: `${res?.data?.bankInformation ?? 0}%`,
      icon: <Bank />,
      link: "/dashboard/getStarted/bank-details",
    },
    {
      title: "Terms and conditions",
      desc: "Read and agree to our terms and conditions of use.",
      percentage: `${res?.data?.termsAndCondition ?? 0}%`,
      icon: <Term />,
      link: "/dashboard/getStarted/terms",
    },
    // {
    // 	title: 'Take dashboard tour (optional)',
    // 	desc: 'Learn about the awesome things you can do on your new dashboard.',
    // 	percentage: '0%',
    // 	icon: <Take />,
    // 	link: '/dashboard/getStarted/tour',
    // },
  ];

  const resendHandler = () => { };
  const switchHandler = () => {
    dispatch(openLoader());
    client
      .patch(`/subsidiary/dashboard/onboarding/switch/business/type`, {})
      .then((res: any) => {
        dispatch(closeLoader());
        if (onboardingState === "Individual") {
          dispatch(onboardingfunc("Company"));
        } else {
          dispatch(onboardingfunc("Individual"));
        }
        dispatch(
          openToastAndSetContent({
            toastContent: res.data.message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      });
  };

  const liveHandler = async () => {
    dispatch(openLoader());
    dispatch(completeOnboardingStepsAction(async (data: any, error: any) => {
      try {
        if (error) throw new Error(error.message);
        dispatch(openToastAndSetContent({
          toastContent: data?.message,
          toastStyles: ToastSuccessStyles,
        }))
        dispatch(await getProfileDetailsAction((medata: any, error: any) => {
          if (error) return;
          dispatch(onboardingfunc(medata?.subsidiaryDetails?.subsidiaries[0]?.businessType));
          dispatch(onVerificationStateAction(medata?.subsidiaryDetails?.subsidiaries[0]?.verificationStatus));
          dispatch(saveOnboardRoute(medata?.routeToGetStarted));
          dispatch(changeNavbar("Dashboard"));
          window.location.assign("/dashboard");
        }))
      } catch (error: any) {
        console.log(error)
        dispatch(closeLoader());
        const { message } = error;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
      finally {
        dispatch(closeLoader());
      }
    }))
  };
  const Data = ({ item }: any) => {
    return (
      <div
        onClick={() => item.link && history.push(item.link)}
        className={styles.reusebox}
      >
        <div className={styles.reuseboxfirst}>{item.icon}</div>
        <div className={styles.reuseboxsecond}>
          <h3>{item.title}</h3>
          <p>{item.desc}</p>
        </div>
        <div className={styles.reuseboxthird}>
          {item.resend ? (
            <p onClick={resendHandler} className={styles.resend}>
              Resend Link
            </p>
          ) : (
            item.percentage
          )}
        </div>
      </div>
    );
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        {!onBoardRoute && (
          <div
            onClick={() => history.goBack()}
            className={styles.wrapperInnerLeft}
          >
            <img src={arrow} alt="" />
            <p>Back</p>
          </div>
        )}

        <div className={styles.wrapperInnerRight}>
          <h2 className={styles.wrapperh2}>We need more information</h2>
          <p className={styles.wrapperp}>
            Your account is currently in{" "}
            <span style={{ color: "red" }}>test mode</span>, so there are a few
            more things to do before you can go live and start receiving
            payments. Follow the steps below to get activated.
          </p>
          <div>
            {onboardingState === "Company" &&
              registeredData.map((item, i) => <Data key={i} item={item} />)}

            {onboardingState === "Individual" &&
              personalData.map((item, i) => <Data key={i} item={item} />)}
          </div>

          {!trigger().hideRequestToGoLive && (
            <div className={styles.buttonsect}>
              <button
                onClick={liveHandler}
                disabled={!trigger().allowRequestGoLove}
                className={styles.button}
              >
                Request to go live
              </button>
              <p className={styles.buttonp}>
                Change your business type to{" "}
                {/* history.push('/dashboard/getStarted') */}
                <span
                  onClick={() => switchHandler()}
                  style={{ color: "#19943c", cursor: "pointer" }}
                >
                  {onboardingState === "Individual" ? "Company" : "Individual"}{" "}
                  Business
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Onboarding;
